import { Box, Button, Grid, Paper, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import React, { useEffect, useState } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useApi } from '../../hooks/useApi';
// import CapsulesCountWidget from './CapsulesCountWidget';
import CoreVersionWidget from './CoreVersionWidget';
import PaymentsProcessingStatusWidget from './PaymentsProcessingStatusWidget';
import ProcessingStatusWidget from './ProcessingStatusWidget';
import ServiceStatusWidget from './ServiceStatusWidget';
import ServiceUptimeWidget from './ServiceUptimeWidget';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    marginRight: 0,
    marginLeft: 0,
  },
}));

const style = {
  backgroundColor: '#747D8D',
  padding: '1px 16px',
  borderRadius: 10,
  color: '#FFF',
  fontSize: 20,
  fontWeight: 500,
};

const Dashboard = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { getServiceStatus } = useApi();
  // const [capsulesCount] = useState(null);
  const [totalCapsules] = useState(0);
  const [serviceStatus, setServiceStatus] = useState(null);
  const [isLoading] = useState(false);

  useEffect(() => {
    getServiceStatus().then(res => {
      setServiceStatus(res.data)
    })
  }, [])

  return (
    <Container className={classes.container} maxWidth="lg">
      <Typography component="div">
        <Box mb={2} fontSize={20} fontWeight={600}>
          {t('translation:dashboard:title')}
        </Box>
      </Typography>

      {isLoading ? (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <CircularProgress color="inherit" size={40} />
        </Box>
      ) : (
        <>

          <Typography component="div">
            <Box mt={4} mb={2} fontSize={18} fontWeight={500}>
              {t('translation:dashboard:status:title')}
            </Box>
          </Typography>

          <Grid container spacing={3}>
            <Grid item container spacing={3}>
              <Grid item xs={12} sm={6} md={4}>
                <ServiceStatusWidget serviceStatus={serviceStatus?.serviceStatus} />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <ServiceUptimeWidget serviceUptime={serviceStatus?.uptime} />
              </Grid>

              <Grid item xs={12} sm={12} md={4}>
                {serviceStatus ? (
                  <PaymentsProcessingStatusWidget
                    cardStatus={serviceStatus?.payCard}
                    bitcoinStatus={serviceStatus?.payBtc}
                    ethereumStatus={serviceStatus?.payEth}
                  />
                ) : null}
              </Grid>
            </Grid>

            <Grid item xs={12}>
              {serviceStatus ? (
                <ProcessingStatusWidget
                  messageBackground={serviceStatus?.msgBackground}
                  messageText={serviceStatus?.msgTextEn}
                />
              ) : null}
            </Grid>

            <Grid item xs={6}>
              {serviceStatus ? <CoreVersionWidget coreVersion={serviceStatus?.coreVersion} /> : null}
            </Grid>
          </Grid>
        </>
      )}
    </Container>
  );
};

export default Dashboard;
