import { Divider, Drawer, List, ListItem, ListItemIcon, ListItemText, Toolbar, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import ExitToApp from '@material-ui/icons/ExitToApp';
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import PhoneInTalk from '@material-ui/icons/PhoneInTalk';
import PolicyIcon from '@material-ui/icons/Policy';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as BankCard } from '../assets/bank-cards.svg';
import { ReactComponent as Chip } from '../assets/chip.svg';
import { ReactComponent as CryptoWallet } from '../assets/crypto-wallet.svg';
import { ReactComponent as FileAccess } from '../assets/file-access.svg';
import { ReactComponent as Other } from '../assets/other.svg';
import { ReactComponent as ServerPassword } from '../assets/server-psw.svg';
import { ReactComponent as WebLogin } from '../assets/web-logins.svg';
import * as ROUTES from '../constants/routes';
import { useApi } from '../hooks/useApi';
import { useAuth } from '../hooks/useAuth';
import { useRouter } from '../hooks/useRouter';
import { useVaultService } from '../hooks/useVaultService';
import ListItemLink from './ListItemLink';
import SwitchButton from './SwitchButton';

const drawerWidth = 304;

const useStyles = makeStyles((theme) => ({
  root: {
    color: '#A6AAB0',
    margin: theme.spacing(0, 1.5),
    paddingTop: 20,
  },
  divider: {
    margin: theme.spacing(1, 1.5, 1, 1.5),
    backgroundColor: '#4E5768',
  },
  button: {
    backgroundColor: '#E41139',
    margin: theme.spacing(0, 1.5),
    height: theme.spacing(5.5),
    minWidth: theme.spacing(5.5),
    overflow: 'hidden',
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    padding: '16px 24px 48px 24px',
    ...theme.mixins.toolbar,
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 48,
  },
  drawerPaper: {
    position: 'fixed',
    whiteSpace: 'nowrap',
    backgroundColor: '#2E3542',
    width: drawerWidth,
    boxShadow: theme.shadows[6],
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: 0,
    [theme.breakpoints.up('sm')]: {
      width: 0,
    },
  },
  listItem: {
    borderRadius: 4,
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(3.5),
    '&.Mui-selected': {
      backgroundColor: '#1F2633',
    },
    '&:hover': {
      color: '#FFFFFF',
    },
  },
  listItemText: {
    fontSize: 16,
  },
  listItemIcon: {
    color: '#A6AAB0',
    minWidth: 52,
  },
}));

const LeftSidebarMobile = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { isDrawerOpened, setDrawerOpened } = props;
  const router = useRouter();
  const auth = useAuth();
  const { resetUser } = useApi();
  const { resetVaultService } = useVaultService();

  const handleSignOut = () => {
    auth.signOut().then(() => {
      resetVaultService();
      resetUser();
      router.history.push(ROUTES.AUTH_SIGN_IN);
    });
  };
  return (
    <Drawer
      id="menubarmobile"
      variant="permanent"
      classes={{
        paper: clsx(classes.drawerPaper, !props.isDrawerOpened && classes.drawerPaperClose),
      }}
      PaperProps={{
        elevation: 2,
      }}
      open={props.isDrawerOpened}
    >
      <Toolbar />
      <Box display="flex" flexDirection="column" justifyContent="space-between">
        <Box>
          <List className={classes.root}>
            <ListItemLink
              to={ROUTES.WEB_SITES_LOGIN}
              primary={t('translation:sidebar:menu:web-sites-logins')}
              icon={<WebLogin />}
              setDrawerOpened={setDrawerOpened}
            />

            <ListItemLink
              to={ROUTES.FILE_ACCESS_PASSWORDS}
              primary={t('translation:sidebar:menu:file-access-passwords')}
              icon={<FileAccess />}
              setDrawerOpened={setDrawerOpened}
            />

            <ListItemLink
              to="/server-logins"
              primary={t('translation:sidebar:menu:server-passwords')}
              icon={<ServerPassword />}
              setDrawerOpened={setDrawerOpened}
            />

            <ListItemLink
              to={ROUTES.BANK_CARDS}
              primary={t('translation:sidebar:menu:bank-cards')}
              icon={<BankCard />}
              setDrawerOpened={setDrawerOpened}
            />

            <ListItemLink
              to={ROUTES.CRYPTO_WALLETS}
              primary={t('translation:sidebar:menu:cryptocurrency-wallets')}
              icon={<CryptoWallet />}
              setDrawerOpened={setDrawerOpened}
            />

            <ListItemLink
              to={ROUTES.HARDWARE_DEVICES}
              primary={t('translation:sidebar:menu:hardware-devices')}
              icon={<Chip />}
              setDrawerOpened={setDrawerOpened}
            />

            <ListItemLink
              to={ROUTES.OTHER_LOGINS}
              setDrawerOpened={setDrawerOpened}
              primary={t('translation:sidebar:menu:other-login')}
              icon={<Other />}
            />

            <ListItemLink
              to={ROUTES.CONTACTS}
              setDrawerOpened={setDrawerOpened}
              primary={t('translation:sidebar:menu:contacts')}
              icon={<PhoneInTalk />}
            />
          </List>

          <Divider className={classes.divider} />

          <List className={classes.root}>
            <ListItemLink
              to="/profile"
              setDrawerOpened={setDrawerOpened}
              primary={t('translation:sidebar:menu:profile')}
              icon={<PermIdentityIcon />}
            />

            {/* <ListItemLink to="/settings" primary={t('translation:sidebar:menu:settings')} icon={<Settings />} /> */}

            <ListItemLink
              to="/faq"
              setDrawerOpened={setDrawerOpened}
              primary={t('translation:sidebar:menu:faq')}
              icon={<HelpOutlineIcon />}
            />

            <ListItemLink
              to="/support"
              setDrawerOpened={setDrawerOpened}
              primary={t('translation:sidebar:menu:support')}
              icon={<HeadsetMicIcon />}
            />

            <ListItemLink
              to="/privacy-policy"
              setDrawerOpened={setDrawerOpened}
              primary={t('translation:sidebar:menu:privacy')}
              icon={<PolicyIcon />}
            />

            <ListItemLink
              to="/terms-of-service"
              setDrawerOpened={setDrawerOpened}
              primary={t('translation:sidebar:menu:terms')}
              icon={<DescriptionOutlinedIcon />}
            />

            <ListItem button className={classes.listItem} onClick={handleSignOut}>
              <ListItemIcon className={classes.listItemIcon}>
                <ExitToApp />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography type="body2" className={classes.listItemText}>
                    {t('translation:sidebar:menu:logout')}
                  </Typography>
                }
              />
            </ListItem>

            {isDrawerOpened && <SwitchButton />}
          </List>
        </Box>

        <Box mt={4}>
          <Typography
            variant="body2"
            component="p"
            style={{
              color: '#FFFFFF',
              margin: '0 28px',
            }}
          >
            {isDrawerOpened ? t('translation:sidebar:version') : null} {process.env.REACT_APP_VERSION}
          </Typography>
        </Box>
      </Box>
    </Drawer>
  );
};

export default LeftSidebarMobile;
