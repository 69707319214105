import axios from 'axios';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import React, { createContext, useContext, useState } from 'react';
import * as ENDPOINTS from '../constants/endpoints';

const ApiContext = createContext(null);

export function ProvideApi({ children }) {
  const api = useProvideApi();
  return <ApiContext.Provider value={api}>{children}</ApiContext.Provider>;
}

export const useApi = () => {
  return useContext(ApiContext);
};

function useProvideApi() {
  axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
  axios.defaults.headers['Accept'] = 'application/json';

  axios.interceptors.request.use(
    (config) => {
      return new Promise((resolve, reject) => {
        firebase
          .auth()
          .currentUser.getIdToken()
          .then((token) => {
            config.headers['Authorization'] = `Bearer ${token}`;
            return resolve(config);
          })
          .catch((error) => {
            return reject(error);
          });
      });
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const [userResponse, setUserResponse] = useState(null);
  const [userFetching, setUserFetching] = useState(false);

  /**
   * Returns back-end API version
   * @returns {Promise}
   */
  const getApiVersion = () => {
    return axios.get(ENDPOINTS.VERSION);
  };

  const getUserApi = () => {
    setUserFetching(true);
    return axios
      .post(ENDPOINTS.GET_USER)
      .then((res) => {
        setUserResponse(res);
        return res;
      })
      .finally(() => setUserFetching(false));
  };

  /**
   * Returns current user information
   * @returns {Promise}
   */
  const getUser = (isNew = false) => {
    if (isNew) {
      return getUserApi();
    }
    if (userResponse) {
      return Promise.resolve(userResponse);
    }
    if (!userFetching) {
      return getUserApi();
    } else {
      return new Promise((resolve) => {
        const id = setInterval(() => {
          if (userResponse) {
            clearInterval(id);
            resolve(userResponse);
          }
        }, 100);
      });
    }
  };

  const resetUser = () => {
    setUserResponse(null);
    setUserFetching(false);
  };

  /**
   * Creates a new capsule with provided "capsuleName"
   * @param capsuleName
   * @returns {Promise}
   */
  const createCapsule = (capsuleName) => {
    return axios.post(ENDPOINTS.CREATE_CAPSULE, { capsuleName });
  };

  /**
   * Updates capsule with provided change set
   * @param capsule
   * @returns {Promise}
   */
  const updateCapsule = (capsule) => {
    return axios.post(ENDPOINTS.UPDATE_CAPSULE, capsule);
  };

  /**
   * Locks capsule
   * @param capsuleId
   * @returns {Promise}
   */
  const sealCapsule = (capsuleId) => {
    return axios.post(ENDPOINTS.SEAL_CAPSULE, { capsuleId });
  };

  /**
   * Deletes capsule with provided "capsuleId"
   * @param capsuleId
   * @returns {Promise}
   */
  const deleteCapsule = (capsuleId) => {
    return axios.post(ENDPOINTS.DELETE_CAPSULE, { capsuleId });
  };

  /**
   * Returns a full list of user capsules
   * @returns {Promise}
   */
  const getCapsules = () => {
    return axios.post(ENDPOINTS.GET_CAPSULES, null);
  };

  /**
   * Returns capsules count grouped by status
   * @returns {Promise}
   */
  const getCapsulesCount = () => {
    return axios.post(ENDPOINTS.GET_CAPSULES_COUNT, null);
  };

  /**
   * Returns a specific capsule by "capsuleId"
   * @param capsuleId
   * @returns {Promise}
   */
  const getCapsule = (capsuleId) => {
    return axios.post(ENDPOINTS.GET_CAPSULE, {
      capsuleId,
    });
  };

  /**
   * Returns created file ID and AES encryption key
   * @param fileData
   * @returns {Promise}
   */
  const addFile = (fileData) => {
    return axios.post(ENDPOINTS.ADD_FILE, { ...fileData });
  };

  /**
   * Deletes file from the server
   * @param fileData
   * @returns {Promise}
   */
  const deleteFile = (fileData) => {
    return axios.post(ENDPOINTS.DELETE_FILE, { ...fileData });
  };

  /**
   * Upload encrypted file binary
   * @param formData
   * @param onUploadProgressCallback
   * @returns {Promise}
   */
  const uploadFile = (formData, onUploadProgressCallback) => {
    return axios.post(ENDPOINTS.UPLOAD_FILE, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: onUploadProgressCallback,
    });
  };

  /**
   * Set a deposit amount
   * @param currency
   * @param amount
   * @returns {Promise}
   */
  const addFunds = (currency, amount) => {
    return axios.post(ENDPOINTS.ADD_FUNDS, { currency, amount });
  };

  /**
   * Set capsule status as paid
   * @param capsuleId
   * @returns {Promise}
   */
  const payCapsule = (capsuleId) => {
    return axios.post(ENDPOINTS.PAY_CAPSULE, { capsuleId });
  };

  const getServiceStatus = () => {
    return axios.get(ENDPOINTS.GET_SERVICE_STATUS);
  };

  const getActivityLog = (params) => {
    return axios.get(ENDPOINTS.GET_ACTIVITY_LOG, { params });
  };

  const getPayments = (params) => {
    return axios.get(ENDPOINTS.GET_PAYMENTS, { params });
  };

  const getCodewordFile = (data) => {
    return axios.post(ENDPOINTS.GET_CODEWORD_FILE, { ...data });
  };

  const getPrices = () => {
    return axios.get(ENDPOINTS.GET_PRICES);
  };

  const requestPrice = (capsuleId, months) => {
    return axios.post(ENDPOINTS.REQUEST_PRICE, { capsuleId, months });
  };

  const prolongCapsule = (capsuleId, months) => {
    return axios.post(ENDPOINTS.PROLONG_CAPSULE, { capsuleId, months });
  };

  const requestPayment = (data) => {
    return axios.post(ENDPOINTS.REQUEST_PAYMENT, { ...data });
  };

  const setUserData = (data) => {
    return axios.post(ENDPOINTS.SET_USER_DATA, { ...data });
  };

  return {
    getUser,
    getApiVersion,
    getActivityLog,
    createCapsule,
    sealCapsule,
    updateCapsule,
    deleteCapsule,
    getCapsule,
    getCapsules,
    getCapsulesCount,
    addFile,
    deleteFile,
    uploadFile,
    addFunds,
    payCapsule,
    getServiceStatus,
    getPayments,
    getCodewordFile,
    getPrices,
    requestPrice,
    prolongCapsule,
    requestPayment,
    setUserData,
    resetUser,
  };
}
