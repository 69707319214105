import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { useRouter } from '../hooks/useRouter';

const useStyles = makeStyles((theme) => ({
  listItem: {
    borderRadius: 4,
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(3.5),
    position: 'relative',
    '&.Mui-selected': {
      backgroundColor: '#1F2633',
    },
    '&:hover': {
      color: '#FFFFFF',
    },
  },
  listItemText: {
    fontSize: 16,
  },
  listItemIcon: {
    color: '#A6AAB0',
    minWidth: 52,
  },
  greenDot: {
    width: 8,
    height: 8,
    borderRadius: 4,
    backgroundColor: 'green',
    position: 'absolute',
    left: 43,
    top: 20,
  },
}));

const ListItemLink = (props) => {
  const classes = useStyles();
  const router = useRouter();
  const { icon, primary, to, setDrawerOpened } = props;

  const CustomLink = React.useMemo(
    () => React.forwardRef((linkProps, ref) => <NavLink ref={ref} to={to} {...linkProps} />),
    [to]
  );

  return (
    <li>
      <ListItem
        button
        className={classes.listItem}
        component={CustomLink}
        onClick={() => {
          if (setDrawerOpened) {
            setDrawerOpened(false);
            window.localStorage.setItem('sidebarOpened', 'false');
          }
        }}
      >
        <ListItemIcon className={classes.listItemIcon}>{icon}</ListItemIcon>
        {router.pathname === to ? <div className={classes.greenDot}></div> : null}
        <ListItemText
          primary={
            <Typography type="body2" className={classes.listItemText}>
              {primary}
            </Typography>
          }
        />
      </ListItem>
    </li>
  );
};

export default ListItemLink;
