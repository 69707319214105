import bcrypt from 'bcryptjs';
import React, { createContext, useContext, useEffect, useState } from 'react';
import * as firebase from 'firebase/app'; // import * as firebase from 'firebase/app'; // SZ 2021-sep-02
import 'firebase/analytics'; // import firebaseOrigin from ' firebase ';  // SZ 2021-sep-02
import { SALT } from '../constants/encoding';

firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
});

firebase.analytics(); // firebaseOrigin.analytics();  // SZ 2021-sep-02

const AuthContext = createContext(null);

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

export const useAuth = () => {
  return useContext(AuthContext);
};

function useProvideAuth() {
  const [user, setUser] = useState(null);
  const [_password, setPassword] = useState('');
  const [passwordHash, setPasswordHash] = useState('');
  const [isReady, setReady] = useState(false);
  const [isLoading, setLoading] = useState(true);

  const signIn = (email, password) => {
    return firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((response) => {
        setUser(response.user);
        setReady(true);
        setPassword(password);
        const passwordHash = bcrypt.hashSync(password, SALT);
        setPasswordHash(passwordHash);
        return response.user;
      });
  };

  const signUp = (email, password) => {
    return firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then((response) => {
        setUser(response.user);
        setReady(true);
        setPassword(password);
        const passwordHash = bcrypt.hashSync(password, SALT);
        setPasswordHash(passwordHash);
        return response.user;
      });
  };

  const signOut = () => {
    return firebase
      .auth()
      .signOut()
      .then(() => {
        setUser(false);
        setPassword('');
        setPasswordHash('');
      });
  };

  const reauthenticateWithCredential = (credentials) => {
    return firebase.auth().currentUser.reauthenticateWithCredential(credentials);
  };

  const sendPasswordResetEmail = (email) => {
    return firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        return true;
      });
  };

  const confirmPasswordReset = (code, password) => {
    return firebase
      .auth()
      .confirmPasswordReset(code, password)
      .then(() => {
        return true;
      });
  };

  const applyActionCode = (code) => {
    return firebase
      .auth()
      .applyActionCode(code)
      .then(() => {
        return true;
      });
  };

  useEffect(() => {
    setUser(null);
    setReady(false);
    setLoading(false);
    const unsubscribe = firebase.auth().onAuthStateChanged((_user) => {
      if (!_user) {
        signOut();
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return {
    user,
    isReady,
    isLoading,
    password: _password,
    passwordHash,
    reauthenticateWithCredential,
    signIn,
    signUp,
    signOut,
    sendPasswordResetEmail,
    confirmPasswordReset,
    applyActionCode,
  };
}
