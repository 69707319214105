import {
  Box,
  Button,
  Checkbox,
  Container,
  Grid,
  Hidden,
  IconButton,
  makeStyles,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { InfoOutlined, Settings } from '@material-ui/icons';
import Add from '@material-ui/icons/Add';
import DeleteOutlineOutlined from '@material-ui/icons/DeleteOutlineOutlined';
import EditOutlined from '@material-ui/icons/EditOutlined';
import Favorite from '@material-ui/icons/Favorite';
import FavoriteBorderOutlined from '@material-ui/icons/FavoriteBorderOutlined';
import History from '@material-ui/icons/History';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useForceUpdate from 'use-force-update';
import { ReactComponent as ExportJson } from '../../assets/export-json.svg';
import { ReactComponent as Filter } from '../../assets/filter.svg';
import Vault from '../../assets/vault.png';
import CopyToClipboard from '../../components/CopyToClipboard';
import DeleteVault from '../../components/DeleteVault';
import EditVaultName from '../../components/EditVaultName';
import MobileFilter from '../../components/MobileFilter';
import TableSwitcherBtns from '../../components/TableSwitcherBtns';
import ViewSwitcher from '../../components/ViewSwitcher';
import { exportToJson } from '../../helpers/exportToJson';
import { useAuth } from '../../hooks/useAuth';
import { useVaultService } from '../../hooks/useVaultService';
import CardView from './CardView';
import CreateWebSitesLogins from './CreateBankCard';
import DeleteItem from './DeleteItem';
import NotFound from './NotFound';
import ViewWebSitesLogins from './ViewBankCard';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    marginRight: 0,
    marginLeft: 0,
    maxWidth: '100%',
  },
  notFoundWrap: {
    width: '100%',
    height: 'calc(100vh - 200px)',
  },
  btnWrap: {
    paddingLeft: 10,
  },
  icon: { padding: 9, color: '#A6AAB0', width: 42 },
  datagrid: {
    '&.MuiDataGrid-root': {
      color: '#A6AAB0',
      fontSize: 16,
    },
    '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
      outline: 'none',
    },
    '&.MuiDataGrid-root .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus': {
      outline: 'none',
    },
    '&.MuiDataGrid-root .MuiDataGrid-footer': {
      display: 'none',
    },
  },
  text: { color: '#2E3542', fontSize: 16, fontWeight: 'normal' },
  textRed: { color: '#E41139', fontSize: 12, fontWeight: 'normal' },
  checkbox: { color: '#A6AAB0' },
  linkText: { color: '#E41139', fontSize: 16, fontWeight: 'normal', textDecoration: 'underline', flex: 'auto' },
}));

const BankCards = () => {
  const classes = useStyles();
  const auth = useAuth();
  const vaultService = useVaultService();
  const { t } = useTranslation();
  const [view, setView] = useState('');
  const { currentVault, updateCurrentVault, recoverItem, isMainVault, searchVaultText, searchType } = vaultService;
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [editRowId, setEditRowId] = useState(null);
  const [viewRowId, setViewRowId] = useState(null);
  const [deleteRowId, setDeleteRowId] = useState(null);
  const [deleteSelected, setDeleteSelected] = useState(false);
  const [viewModalIsOpen, setViewModalIsOpen] = useState(false);
  const [deleteVaultModalIsOpen, setDeleteVaultModalIsOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [editVaultModalIsOpen, setEditVaultModalIsOpen] = useState(false);
  const [showNumberArr, setShowNumberArr] = useState([]);
  const [showCVCArr, setShowCVCArr] = useState([]);
  const [checkedArr, setCheckedArr] = useState([]);
  const [tableMode, setTableMode] = useState('allitems');
  const [filterModalIsOpen, setFilterModalIsOpen] = useState(false);
  const forceUpdate = useForceUpdate();

  let lastVaultId = '';
  const isDeleteMode = tableMode === 'deleted';
  const isFavoritesMode = tableMode === 'favorites';

  const handleClickShowCVC = (key) => {
    const foundShowCVCObj = showCVCArr.find((item) => item.key === key);
    if (foundShowCVCObj) {
      foundShowCVCObj.showCVC = !foundShowCVCObj.showCVC;
      const _showCVCArr = [...showCVCArr];
      setShowCVCArr(_showCVCArr);
    } else {
      const _showCVCArr = [...showCVCArr, { key: key, showCVC: true }];
      setShowCVCArr(_showCVCArr);
    }
  };

  const handleClickShowNumber = (key) => {
    const foundShowNumberObj = showNumberArr.find((item) => item.key === key);
    if (foundShowNumberObj) {
      foundShowNumberObj.showNumber = !foundShowNumberObj.showNumber;
      const _showNumberArr = [...showNumberArr];
      setShowNumberArr(_showNumberArr);
    } else {
      const _showNumberArr = [...showNumberArr, { key: key, showNumber: true }];
      setShowNumberArr(_showNumberArr);
    }
  };

  const handleClickFavorite = (id) => {
    const currentRecBank = currentVault.rec_bank.find((item) => item.id === id);
    if (currentRecBank) {
      currentRecBank.fav = !currentRecBank.fav;
      updateCurrentVault();
      forceUpdate();
    }
  };

  const showEditModal = (id) => {
    setModalIsOpen(true);
    setEditRowId(id);
  };

  const showViewModal = (id) => {
    setViewRowId(id);
    setEditRowId(id);
    setViewModalIsOpen(true);
  };

  const deleteOneRow = (id) => {
    setDeleteRowId(id);
    setDeleteModalIsOpen(true);
  };

  const deleteSelectedRow = () => {
    setDeleteSelected(true);
    setDeleteModalIsOpen(true);
  };

  const recoverDeletedItem = (id) => {
    recoverItem(id).then((res) => {
      if (res && res.status === 200) {
        const data = res.data;
        currentVault.rec_bank = currentVault.rec_bank.filter((item) => item.id !== data.id);
        const newRecWeb = {
          ...data,
        };
        currentVault.rec_bank.push(newRecWeb);
        updateCurrentVault();
      }
    });
  };

  const selectCheckAll = () => {
    let newCheckedArr = [];
    if (!isFavoritesMode) {
      newCheckedArr = checkedArr.map((item) => ({
        ...item,
        checked: !checkAll,
      }));
    } else {
      const rowsFavorite = currentVault.rec_bank.filter((item) => item.fav && !item.del);
      newCheckedArr = checkedArr.map((item) => {
        const found = rowsFavorite.find((i) => i.id === item.id);
        return {
          ...item,
          checked: found ? !checkAll : item.checked,
        };
      });
    }
    setCheckedArr(newCheckedArr);
    setCheckAll(!checkAll);
  };

  const init = () => {};

  useEffect(() => {
    if (auth.isReady) {
      init();
    }
  }, [auth]);

  useEffect(() => {
    if (currentVault && currentVault.rec_bank) {
      if (currentVault.id === lastVaultId) {
        const selectedItems = currentVault.rec_bank.map((item) => {
          const found = checkedArr.find((i) => i.id === item.id);
          let checked = false;
          if (found) checked = found.checked;
          return {
            id: item.id,
            checked,
          };
        });
        setCheckedArr(selectedItems);
      } else {
        setCheckAll(false);
        const selectedItems = currentVault.rec_bank.map((item) => {
          return {
            id: item.id,
            checked: false,
          };
        });
        setCheckedArr(selectedItems);
      }
      // eslint-disable-next-line
      lastVaultId = currentVault.id;
    }
    // eslint-disable-next-line
  }, [currentVault]);

  const selectCheckedValue = (id) => {
    const found = checkedArr.find((i) => i.id === id);
    if (found) found.checked = !found.checked;
    setCheckedArr([...checkedArr]);
  };

  const columns = [
    {
      field: 'checkbox',
      headerName: ' ',
      width: !isDeleteMode ? 60 : 0,
      disableColumnMenu: true,
      renderCell: (params) => {
        const found = checkedArr.find((i) => i.id === params.row.id);
        if (!found) return null;
        return (
          <Box
            style={{ width: '100%' }}
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="flex-start"
          >
            <Checkbox
              value="checked"
              checked={found && found.checked}
              onChange={() => selectCheckedValue(params.row.id)}
              className={classes.checkbox}
            />
          </Box>
        );
      },
    },
    {
      field: 'name',
      headerName: t('translation:commonPages:bankName'),
      flex: !isDeleteMode ? 0.5 : 1,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <Box
            style={{ width: '100%', paddingRight: 20 }}
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="flex-start"
          >
            <CopyToClipboard text={params.row.name} />
            <Typography component="p" className={classes.text}>
              {params.row.name}&nbsp;&nbsp;
            </Typography>
          </Box>
        );
      },
    },
    {
      field: 'cname',
      headerName: t('translation:commonPages:cardOwner'),
      flex: !isDeleteMode ? 0.5 : 0,
      width: !isDeleteMode ? undefined : 0,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <Box
            style={{ width: '100%', paddingRight: 20 }}
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="flex-start"
          >
            <CopyToClipboard text={params.row.cname} />
            <Typography component="p" className={classes.text}>
              {params.row.cname}&nbsp;&nbsp;
            </Typography>
          </Box>
        );
      },
    },
    {
      field: 'cnumber',
      headerName: t('translation:commonPages:cardNumber'),
      width: !isDeleteMode ? 300 : 0,
      disableColumnMenu: true,
      renderCell: (params) => {
        const arr = params.row.cnumber.split(' ');
        const last4digits = arr.length && arr[arr.length - 1] ? arr[arr.length - 1] : '';
        const hiddenNumber = '**** ' + last4digits;
        const foundShowNumberObj = showNumberArr.find((item) => item.key === params.id);
        if (isDeleteMode) return null;
        return (
          <Box
            style={{ width: '100%' }}
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="flex-start"
          >
            <Box>
              <IconButton
                className={classes.icon}
                aria-label="view list"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
                onClick={() => handleClickShowNumber(params.id)}
              >
                {foundShowNumberObj && foundShowNumberObj.showNumber ? <Visibility /> : <VisibilityOff />}
              </IconButton>
              <CopyToClipboard text={params.row.cnumber} />
            </Box>
            <Typography component="p" className={classes.text}>
              {foundShowNumberObj && foundShowNumberObj.showNumber ? params.row.cnumber : hiddenNumber}
              &nbsp;&nbsp;
            </Typography>
          </Box>
        );
      },
    },
    {
      field: 'ccvc',
      headerName: 'CVC/CVV',
      width: !isDeleteMode ? 200 : 0,
      disableColumnMenu: true,
      renderCell: (params) => {
        const hiddenCVC = '****';
        const foundShowCVCObj = showCVCArr.find((item) => item.key === params.id);
        if (isDeleteMode) return null;
        return (
          <Box
            style={{ width: '100%' }}
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="flex-start"
          >
            <Box>
              <IconButton
                className={classes.icon}
                aria-label="view list"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
                onClick={() => handleClickShowCVC(params.id)}
              >
                {foundShowCVCObj && foundShowCVCObj.showCVC ? <Visibility /> : <VisibilityOff />}
              </IconButton>
              <CopyToClipboard text={params.row.ccvc} />
            </Box>
            <Typography component="p" className={classes.text}>
              {foundShowCVCObj && foundShowCVCObj.showCVC ? params.row.ccvc : hiddenCVC}
              &nbsp;&nbsp;
            </Typography>
          </Box>
        );
      },
    },
    {
      field: 'cexpire',
      headerName: t('translation:commonPages:exdate'),
      width: !isDeleteMode ? 150 : 0,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <Box
            style={{ width: '100%', paddingRight: 20 }}
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="flex-start"
          >
            <CopyToClipboard text={params.row.cexpire} />
            <Typography component="p" className={classes.text}>
              {params.row.cexpire}&nbsp;&nbsp;
            </Typography>
          </Box>
        );
      },
    },
    {
      field: 'deldate',
      headerName: t('translation:commonPages:deldate'),
      width: !isDeleteMode ? 0 : 230,
      disableColumnMenu: true,
      renderCell: (params) => {
        if (!isDeleteMode) return null;
        const end = moment().subtract(30, 'days');
        const current = moment(params.row.del);
        return (
          <Box display="flex" flexDirection="column" alignItems="flex-start">
            <Typography component="p" className={classes.text}>
              {moment(params.row.del).fromNow()}
            </Typography>
            <Typography component="p" className={classes.textRed}>
              {current.diff(end, 'days') + ' days left before permanent deletion'}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: 'action',
      headerName: t('translation:commonPages:action'),
      width: !isDeleteMode ? 200 : 200,
      disableColumnMenu: true,
      renderCell: (params) => {
        const currentRecBank = currentVault.rec_bank.find((item) => item.id === params.row.id);

        return (
          <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center">
            {!isDeleteMode ? (
              <Tooltip title={params.row.description}>
                <IconButton
                  className={classes.icon}
                  aria-label="view list"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  color="inherit"
                  onClick={() => {
                    setViewRowId(params.row.id);
                    setEditRowId(params.row.id);
                    setViewModalIsOpen(true);
                  }}
                >
                  <InfoOutlined />
                </IconButton>
              </Tooltip>
            ) : (
              <div style={{ width: 42 }}></div>
            )}
            {!isDeleteMode && (
              <IconButton
                className={classes.icon}
                aria-label="view list"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
                style={{ color: currentRecBank && currentRecBank.fav ? '#E41139' : '#A6AAB0' }}
                onClick={() => handleClickFavorite(params.row.id)}
              >
                {currentRecBank && currentRecBank.fav ? <Favorite /> : <FavoriteBorderOutlined />}
              </IconButton>
            )}
            {!isDeleteMode && (
              <IconButton
                className={classes.icon}
                aria-label="view list"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
                onClick={() => {
                  setModalIsOpen(true);
                  setEditRowId(params.row.id);
                }}
              >
                <EditOutlined />
              </IconButton>
            )}
            {!isDeleteMode && (
              <IconButton
                className={classes.icon}
                aria-label="view list"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
                onClick={() => deleteOneRow(params.row.id)}
              >
                <DeleteOutlineOutlined />
              </IconButton>
            )}
            {isDeleteMode && (
              <IconButton
                className={classes.icon}
                aria-label="view list"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
                onClick={() => recoverDeletedItem(params.row.id)}
              >
                <History />
              </IconButton>
            )}
          </Box>
        );
      },
    },
  ];

  if (!currentVault) return null;

  const filterFunc = (item) => {
    if (searchType === 'mainfields') {
      return (
        item.name.toLowerCase().indexOf(searchVaultText.toLowerCase()) > -1 ||
        item.ctype.toLowerCase().indexOf(searchVaultText.toLowerCase()) > -1 ||
        item.cname.toLowerCase().indexOf(searchVaultText.toLowerCase()) > -1 ||
        item.cnumber.toLowerCase().indexOf(searchVaultText.toLowerCase()) > -1 ||
        item.cexpire.toLowerCase().indexOf(searchVaultText.toLowerCase()) > -1
      );
    }
    if (searchType === 'allfields') {
      return (
        item.name.toLowerCase().indexOf(searchVaultText.toLowerCase()) > -1 ||
        item.ctype.toLowerCase().indexOf(searchVaultText.toLowerCase()) > -1 ||
        item.cname.toLowerCase().indexOf(searchVaultText.toLowerCase()) > -1 ||
        item.cnumber.toLowerCase().indexOf(searchVaultText.toLowerCase()) > -1 ||
        item.cexpire.toLowerCase().indexOf(searchVaultText.toLowerCase()) > -1 ||
        item.desc.toLowerCase().indexOf(searchVaultText.toLowerCase()) > -1
      );
    }
  };

  const rowsAll = currentVault.rec_bank
    .filter((item) => !item.del)
    .filter(filterFunc)
    .map((item) => {
      return {
        id: item.id,
        name: item.name,
        ctype: item.ctype,
        cname: item.cname,
        description: item.desc,
        cpin: item.cpin,
        ccurr: item.ccurr,
        cnumber: item.cnumber,
        ccvc: item.ccvc,
        cexpire: item.cexpire,
        action: '',
      };
    });

  const rowsFavorite = currentVault.rec_bank
    .filter((item) => item.fav && !item.del)
    .filter(filterFunc)
    .map((item) => {
      return {
        id: item.id,
        name: item.name,
        ctype: item.ctype,
        cpin: item.cpin,
        ccurr: item.ccurr,
        cname: item.cname,
        description: item.desc,
        cnumber: item.cnumber,
        ccvc: item.ccvc,
        cexpire: item.cexpire,
        action: '',
      };
    });

  const rowsDeleted = currentVault.rec_bank
    .filter((item) => item.del && moment().diff(moment(item.del), 'days') < 30)
    .map((item) => {
      return {
        id: item.id,
        name: item.name,
        del: item.del,
        action: '',
      };
    });

  let rows = [];
  switch (tableMode) {
    case 'deleted':
      rows = rowsDeleted;
      break;
    case 'favorites':
      rows = rowsFavorite;
      break;
    case 'allitems':
    default:
      rows = rowsAll;
      break;
  }

  const disableCheckAll = checkedArr.length && checkedArr.reduce((a, c) => a || c.checked, false);

  return (
    <Container className={classes.container}>
      <Grid container direction="row" alignItems="center">
        <Grid item md={4}>
          <Typography component="div">
            <Button onClick={() => setModalIsOpen(true)} variant="contained" color="primary" size="large">
              <Add />
              &nbsp;
              {t('translation:websiteslogin:createnew')}
            </Button>
          </Typography>
          {modalIsOpen && (
            <CreateWebSitesLogins
              editRowId={editRowId}
              onClose={() => {
                setModalIsOpen(false);
                setEditRowId(null);
              }}
            />
          )}
          {filterModalIsOpen && (
            <MobileFilter
              tableMode={tableMode}
              onSelect={(mode) => {
                setTableMode(mode);
              }}
              onClose={() => {
                setFilterModalIsOpen(false);
              }}
            />
          )}
          {viewModalIsOpen && (
            <ViewWebSitesLogins
              rowId={viewRowId}
              onClose={() => {
                setViewModalIsOpen(false);
                setViewRowId(null);
                setEditRowId(null);
              }}
              onEdit={() => {
                setModalIsOpen(true);
              }}
            />
          )}
          {deleteModalIsOpen && (
            <DeleteItem
              rowId={deleteRowId}
              deleteSelected={deleteSelected}
              checkedArr={checkedArr}
              onClose={() => {
                setDeleteModalIsOpen(false);
                setDeleteRowId(null);
                setDeleteSelected(null);
              }}
            />
          )}
          {editVaultModalIsOpen && (
            <EditVaultName
              onClose={() => {
                setEditVaultModalIsOpen(false);
              }}
            />
          )}
          {deleteVaultModalIsOpen && (
            <DeleteVault
              onClose={() => {
                setDeleteVaultModalIsOpen(false);
              }}
            />
          )}
        </Grid>
        <Grid item md={4} style={{ width: '100%', marginTop: 10 }}>
          <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
            <img alt="icon" src={Vault} style={{ width: 35 }} />
            &nbsp;
            <IconButton
              className={classes.icon}
              aria-label="view list"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              color="inherit"
              onClick={() => {
                setEditVaultModalIsOpen(true);
              }}
            >
              <Settings />
            </IconButton>
            <Typography component="p" style={{ fontWeight: 500, fontSize: window.innerWidth > 560 ? 22 : 17 }}>
              {currentVault.name} / <b>{t('translation:commonPages:bankCards')}</b>
            </Typography>
            {!isMainVault() && (
              <IconButton
                className={classes.icon}
                aria-label="view list"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
                onClick={() => setDeleteVaultModalIsOpen(true)}
              >
                <DeleteOutlineOutlined />
              </IconButton>
            )}
          </Box>
        </Grid>
        <Grid item md={4}></Grid>
      </Grid>
      {!currentVault.rec_bank.length ? (
        <NotFound />
      ) : (
        <>
          <Grid container direction="row" alignItems="center" style={{ paddingTop: 20 }}>
            <Grid item xs={4}>
              <Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-start">
                <Checkbox
                  value="checked"
                  checked={checkAll}
                  onChange={() => selectCheckAll()}
                  className={classes.checkbox}
                />
                {disableCheckAll && (
                  <>
                    <IconButton
                      className={classes.icon}
                      aria-label="view list"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      color="inherit"
                      onClick={() =>
                        exportToJson(checkedArr, currentVault.rec_bank, [
                          'name',
                          'ctype',
                          'ccurr',
                          'cname',
                          'cnumber',
                          'ccvc',
                          'cissue',
                          'cexpire',
                          'cpin',
                          'desc',
                        ])
                      }
                    >
                      <ExportJson />
                    </IconButton>
                    <IconButton
                      className={classes.icon}
                      aria-label="view list"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      color="inherit"
                      onClick={() => deleteSelectedRow()}
                    >
                      <DeleteOutlineOutlined />
                    </IconButton>
                  </>
                )}
              </Box>
            </Grid>
            <Grid item xs={8}>
              <Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-end">
                <Hidden xsDown>
                  <TableSwitcherBtns onChangeTableMode={(item) => setTableMode(item)} />
                </Hidden>
                <Hidden smUp>
                  <IconButton
                    className={classes.icon}
                    aria-label="view list"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    color="inherit"
                    onClick={() => setFilterModalIsOpen(true)}
                  >
                    <Filter />
                  </IconButton>
                </Hidden>
                <ViewSwitcher onChangeViewMode={(mode) => setView(mode)} />
              </Box>
            </Grid>
          </Grid>
          {view === 'table' && (
            <Box style={{ height: 'auto', backgroundColor: '#FFFFFF' }} mt={2} mb={6}>
              <DataGrid
                disableSelectionOnClick
                columns={columns}
                autoHeight
                rowHeight={isDeleteMode ? 72 : 52}
                pagination
                hideFooterPagination={rows.length > 100 ? false : true}
                rows={rows}
                onCellDoubleClick={(params) => {
                  setViewRowId(params.row.id);
                  setEditRowId(params.row.id);
                  setViewModalIsOpen(true);
                }}
                className={classes.datagrid}
              />
            </Box>
          )}
          {view === 'card' && (
            <Grid container direction="row" alignItems="flex-start" style={{ paddingTop: 20 }}>
              {rows.map((item, key) => {
                const currentRecBank = currentVault.rec_bank.find((i) => item.id === i.id);
                const checked = checkedArr.find((i) => i.id === item.id);
                return (
                  <Grid item md={4} key={key} style={{ minWidth: 300 }}>
                    <CardView
                      data={item}
                      showEditModal={showEditModal}
                      showViewModal={showViewModal}
                      handleClickFavorite={handleClickFavorite}
                      currentRecBank={currentRecBank}
                      selectCheckedValue={() => selectCheckedValue(item.id)}
                      recoverDeletedItem={() => recoverDeletedItem(item.id)}
                      checked={checked}
                      isDeleteMode={isDeleteMode}
                      deleteOneRow={() => deleteOneRow(item.id)}
                    />
                  </Grid>
                );
              })}
            </Grid>
          )}
        </>
      )}
    </Container>
  );
};

export default BankCards;
