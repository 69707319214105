import { Box, Button, Hidden, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Search from '@material-ui/icons/Search';
import classNames from 'classnames';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useRouter } from '../hooks/useRouter';
import { useVaultService } from '../hooks/useVaultService';

const useStyles = makeStyles(() => ({
  inputWrap: { position: 'relative' },
  iconWrap: { position: 'absolute', left: 10, top: 8, zIndex: 20, color: '#A6AAB0' },
  input: {
    position: 'relative',
    minWidth: 240,
    height: 40,
    background: '#F4F8FC',
    border: '1px solid #E3E3E3',
    borderRadius: '4px 0 0 4px',
    paddingLeft: 34,
    color: '#000',
    fontSize: 16,
  },
  btnWrap: {
    height: 40,
    borderTop: '1px solid #E3E3E3',
    borderRight: '1px solid #E3E3E3',
    borderBottom: '1px solid #E3E3E3',
    borderRadius: '0 4px 4px 0',
    paddingLeft: 10,
  },
  link: {
    fontSize: 14,
    color: '#000',
    textDecoration: 'underline',
    fontWeight: 500,
    marginLeft: 20,
  },
  btn: {
    textTransform: 'none',
    paddingLeft: 20,
    paddingRight: 20,
    marginRight: 10,
    padding: 5,
    opacity: 0.4,
    border: '1px solid transparent',
    '&:hover': {
      opacity: 1,
    },
    '&.active': {
      opacity: 1,
      border: '1px solid #2E3542',
    },
  },
}));

const SearchWidget = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { control } = useForm();
  const router = useRouter();
  const { searchVaultText, searchType, setSearchVaultText, setSearchType } = useVaultService();

  useEffect(() => {
    router.history.listen((_router) => {
      setSearchVaultText('');
    });
    // eslint-disable-next-line
  }, []);

  return (
    <Box display="flex" alignItems="center">
      <Hidden smUp>
        <Box display="flex" alignItems="center">
          <IconButton
            style={{
              backgroundColor: searchVaultText !== '' ? '#E41139' : 'transparent',
              color: searchVaultText !== '' ? 'white' : 'rgba(0, 0, 0, 0.54)',
            }}
            onClick={() => props.openMobileSearch()}
            edge="start"
          >
            <Search />
          </IconButton>
        </Box>
      </Hidden>
      <Hidden xsDown>
        <Controller
          as={
            <div className={classes.inputWrap}>
              <div className={classes.iconWrap}>
                <Search />
              </div>
              <input
                type="text"
                onChange={(event) => setSearchVaultText(event.target.value)}
                value={searchVaultText}
                className={classes.input}
                placeholder={t('translation:header:search')}
              />
            </div>
          }
          name="search"
          control={control}
          defaultValue={searchVaultText}
        />
      </Hidden>
      <Hidden mdDown>
        <Box
          display="flex"
          alignItems="center"
          flexDirection="row"
          justifyContent="space-around"
          flexGrow={10}
          className={classes.btnWrap}
        >
          <Button
            color="primary"
            size="small"
            className={classNames(classes.btn, searchType === 'mainfields' ? 'active' : '')}
            onClick={() => setSearchType('mainfields')}
          >
            {t('translation:header:mainfields')}
          </Button>
          <Button
            color="primary"
            size="small"
            className={classNames(classes.btn, searchType === 'allfields' ? 'active' : '')}
            onClick={() => setSearchType('allfields')}
          >
            {t('translation:header:allfields')}
          </Button>
        </Box>
      </Hidden>
      {/* <Hidden xsDown>
        <Box display="flex" alignItems="center">
          <Link href="#" className={classes.link}>
            {t('translation:header:advancedsearch')}
          </Link>
        </Box>
      </Hidden> */}
    </Box>
  );
};

export default SearchWidget;
