import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import PaletteOutlinedIcon from '@material-ui/icons/PaletteOutlined';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import TodayOutlinedIcon from '@material-ui/icons/TodayOutlined';
import VpnKeyOutlinedIcon from '@material-ui/icons/VpnKeyOutlined';
import WarningOutlinedIcon from '@material-ui/icons/WarningOutlined';
import Alert from '@material-ui/lab/Alert';
import React, { useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FacebookIcon, LinkedinIcon, RedditIcon, TelegramIcon, TwitterIcon, VKIcon, WhatsappIcon } from 'react-share';
import bitcoinLogoImg from '../../assets/bitcoin-logo.svg';
import ethereumLogoImg from '../../assets/ethereum.svg';
import TooltipIcon from '../../components/TooltipIcon';
import * as ROUTES from '../../constants/routes';
import { useApi } from '../../hooks/useApi';
import { useAuth } from '../../hooks/useAuth';
import ProfileChangeEmailDialog from './ProfileChangeEmailDialog';
import ProfileChangeNicknameDialog from './ProfileChangeNicknameDialog';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    marginRight: 0,
    marginLeft: 0,
  },
  wrapper: {
    padding: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3),
    },
  },
  walletAddress: {
    wordBreak: 'break-all',
  },
}));

const Profile = () => {
  const classes = useStyles();
  const auth = useAuth();
  const api = useApi();
  const { t } = useTranslation();

  const [userInfo, setUserInfo] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const [isChangeNicknameDialogOpened, setChangeNicknameDialogOpened] = useState(false);
  const [isChangeEmailDialogOpened, setChangeEmailDialogOpened] = useState(false);

  const [snackbarOpened, setSnackbarOpened] = useState(false);

  /* console.log('bf init'); */
  const init = () => {
    /* console.log('init'); */
    setLoading(true);
    api
      .getUser()
      .then((response) => {
        /* console.log('getUser INFO res'); */
        setUserInfo(response.data);
      })
      .finally(() => {
        /* console.log('getUser INFO fin'); */
        setLoading(false);
      });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpened(false);
  };

  useEffect(() => {
    if (auth.isReady) {
      init();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  return (
    <Container className={classes.container} maxWidth="lg">
      <Typography component="div">
        <Box mb={2} fontSize={20} fontWeight={600}>
          {t('translation:profile:title')}
        </Box>
      </Typography>

      {auth.user ? (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper elevation={1}>
              <Box className={classes.wrapper}>
                <Grid container direction="row" justify="space-between" alignItems="center">
                  <Grid item md={6}>
                    <Box>
                      <Box display="flex" alignItems="center">
                        <Typography component="span">
                          <Box fontSize={16} ml={1} mr={1}>
                            {t('translation:profile:accountBalance')}
                          </Box>
                        </Typography>

                        <Typography component="span">
                          <Box mr={1}>
                            {isLoading ? (
                              <CircularProgress size={18} />
                            ) : (
                              <Typography component="span">
                                <Box fontSize={18} fontWeight={600}>
                                  {Number(userInfo?.balance).toFixed(2) + ' US$'}
                                </Box>
                              </Typography>
                            )}
                          </Box>
                        </Typography>

                        <TooltipIcon title={t('translation:profile:accountBalanceTooltip')} placement="top" />
                      </Box>

                      <Box>
                        <Button
                          style={{ color: '#0064C2', fontWeight: 600 }}
                          component={Link}
                          to={ROUTES.USER_PROFILE_PAYMENTS_HISTORY}
                        >
                          {t('translation:profile:viewPaymentHistory')}
                        </Button>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item md={6}>
                    <Box textAlign="right">
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        component={Link}
                        to={ROUTES.USER_PROFILE_TOP_UP_BALANCE}
                      >
                        {t('translation:profile:topUp')}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>

                <Box mt={1} mb={1}>
                  <List>
                    <ListItem disableGutters>
                      <ListItemIcon>
                        <PersonOutlineOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Box style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{auth.user.displayName}</Box>
                        }
                        secondary={t('translation:profile:nickname')}
                      />
                      <ListItemSecondaryAction>
                        <IconButton edge="end" onClick={() => setChangeNicknameDialogOpened(true)}>
                          <EditOutlinedIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>

                    <ListItem disableGutters>
                      <ListItemIcon>
                        <EmailOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={<Box style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{auth.user.email}</Box>}
                        secondary={t('translation:profile:email')}
                      />
                      <ListItemSecondaryAction>
                        <IconButton
                          disabled
                          edge="end"
                          onClick={() => {
                            setChangeEmailDialogOpened(true);
                          }}
                        >
                          <EditOutlinedIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>

                    <ListItem disableGutters>
                      <ListItemIcon>
                        <VpnKeyOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText primary={'************'} secondary={t('translation:profile:password')} />
                      <ListItemSecondaryAction>
                        <IconButton edge="end" disabled>
                          <EditOutlinedIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>

                    <ListItem disableGutters>
                      <ListItemIcon>
                        <TodayOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText primary={'MM/DD/YYYY'} secondary={t('translation:profile:dateFormat')} />
                      <ListItemSecondaryAction>
                        <IconButton edge="end" disabled>
                          <EditOutlinedIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>

                    <ListItem disableGutters>
                      <ListItemIcon>
                        <PaletteOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText primary={'Grafit'} secondary={t('translation:profile:theme')} />
                      <ListItemSecondaryAction>
                        <IconButton edge="end" disabled>
                          <EditOutlinedIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  </List>
                </Box>

                <Divider light />

                <Box mt={1} mb={1}>
                  <List>
                    <ListItem disableGutters>
                      <ListItemIcon>
                        <Box mr={4}>
                          <img src={bitcoinLogoImg} alt={bitcoinLogoImg} width={65} />
                        </Box>
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          isLoading ? (
                            <CircularProgress size={18} />
                          ) : (
                            <Box className={classes.walletAddress}>{userInfo?.btcAddress}</Box>
                          )
                        }
                        secondary={t('translation:profile:bitcoinDescription')}
                      />
                      <ListItemSecondaryAction>
                        <CopyToClipboard text={userInfo?.btcAddress} onCopy={() => setSnackbarOpened(true)}>
                          <IconButton>
                            <FileCopyOutlinedIcon />
                          </IconButton>
                        </CopyToClipboard>
                      </ListItemSecondaryAction>
                    </ListItem>

                    <ListItem disableGutters>
                      <ListItemIcon>
                        <Box mr={4}>
                          <img src={ethereumLogoImg} alt={ethereumLogoImg} width={65} />
                        </Box>
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          isLoading ? (
                            <CircularProgress size={18} />
                          ) : (
                            <Box className={classes.walletAddress}>{'0x' + userInfo?.ethAddress}</Box>
                          )
                        }
                        secondary={t('translation:profile:ethereumDescription')}
                      />
                      <ListItemSecondaryAction>
                        <CopyToClipboard text={'0x' + userInfo?.ethAddress} onCopy={() => setSnackbarOpened(true)}>
                          <IconButton>
                            <FileCopyOutlinedIcon />
                          </IconButton>
                        </CopyToClipboard>
                      </ListItemSecondaryAction>
                    </ListItem>
                  </List>
                </Box>

                <Divider light />

                <Box mt={1} mb={1} pt={2} pb={2}>
                  <Typography component="div">
                    <Box fontSize={16}>{t('translation:profile:socialTitle')}</Box>
                  </Typography>

                  <Box mt={2} display="flex">
                    <Box mr={1}>
                      <a
                        href="https://api.whatsapp.com/send?text=https%3A%2F%2FallPWD.com+-+the+ultimate+secure+vault+for+your+passwords%2C+website+logins%2C+bank+cards%2C+cryptocurrency+wallets%2C+server+logins%2C+secret+contacts%2C+etc"
                        target="_blank"
                        rel="noreferrer"
                        data-action="share/whatsapp/share"
                      >
                        <WhatsappIcon round size={36} />
                      </a>
                    </Box>

                    <Box mr={1}>
                      <a
                        href="https://twitter.com/intent/tweet?text=https%3A%2F%2FallPWD.com+-+the+ultimate+secure+vault+for+your+passwords%2C+website+logins%2C+bank+cards%2C+cryptocurrency+wallets%2C+server+logins%2C+secret+contacts%2C+etc"
                        target="_blank"
                        rel="noreferrer"
                        data-action="share/twitter/share"
                      >
                        <TwitterIcon round size={36} />
                      </a>
                    </Box>
                    <Box mr={1}>
                      <a
                        href="https://facebook.com/sharer.php?display=popup&u=allpwd.com"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FacebookIcon round size={36} />
                      </a>
                    </Box>
                    <Box mr={1}>
                      <a href="https://vk.com/share.php?url=http://allpwd.com" target="_blank" rel="noreferrer">
                        <VKIcon round size={36} />
                      </a>
                    </Box>
                    <Box mr={1}>
                      <a
                        href="https://www.linkedin.com/sharing/share-offsite/?url=https%3A%2F%2FallPWD.com"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <LinkedinIcon round size={36} />
                      </a>
                    </Box>
                    <Box mr={1}>
                      <a href="https://reddit.com/submit?url=https://allpwd.com" target="_blank" rel="noreferrer">
                        <RedditIcon round size={36} />
                      </a>
                    </Box>
                    <Box mr={1}>
                      <a
                        href="https://t.me/share/url?url=https://allPWD.com&text=**allPWD.com**+-+the+ultimate+secure+vault+for+your+passwords%2C+website+logins%2C+bank+cards%2C+cryptocurrency+wallets%2C+server+logins%2C+secret+contacts%2C+etc"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <TelegramIcon round size={36} />
                      </a>
                    </Box>
                  </Box>
                </Box>

                <Divider light />

                <Box mt={1} pt={2} display="flex" justifyContent="space-between" alignItems="center">
                  <Box display="flex" alignItems="center">
                    <Box mr={2}>
                      <WarningOutlinedIcon style={{ color: '#FF9800' }} />
                    </Box>
                    <Typography component="div">
                      <Box fontSize={14}>{t('translation:profile:deleteDescription')}</Box>
                    </Typography>
                  </Box>

                  <Box>
                    <Button style={{ color: '#B00020' }}>{t('translation:profile:deleteAccount')}</Button>
                  </Box>
                </Box>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      ) : (
        <CircularProgress />
      )}

      <ProfileChangeNicknameDialog
        nickname={auth.user.displayName}
        open={isChangeNicknameDialogOpened}
        onClose={() => {
          setChangeNicknameDialogOpened(false);
        }}
      />

      <ProfileChangeEmailDialog
        email={auth.user.email}
        open={isChangeEmailDialogOpened}
        onClose={() => {
          setChangeEmailDialogOpened(false);
        }}
      />

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={snackbarOpened}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity="success">
          Copied to clipboard!
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default Profile;
