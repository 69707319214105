import {
  Box,
  Button,
  CircularProgress,
  Container,
  createMuiTheme,
  makeStyles,
  Snackbar,
  TextField,
  Typography,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../hooks/useAuth';

const defaultTheme = createMuiTheme();
const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2, 2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%',
  },
  title: {
    margin: theme.spacing(0, 0, 4.5, 0),
  },
  header: {
    margin: theme.spacing(0, 0, 5, 0),
  },

  subtitle: {},
  bottom: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: theme.spacing(5, 0, 0, 0),
  },
  submit: {
    margin: theme.spacing(3, 0, 5, 0),
  },
  error: {
    color: theme.palette.error.main,
  },
  input: {
    fontSize: defaultTheme.typography.fontSize + 10,
  },
}));

const INITIAL_STATE = {
  email: '',
  error: null,
};

const ForgotPassword = () => {
  const classes = useStyles();
  const auth = useAuth();
  const { t } = useTranslation();
  const [state, setState] = useState(INITIAL_STATE);
  const [isLoading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const onSubmit = (event) => {
    const { email } = state;

    setLoading(true);
    auth
      .sendPasswordResetEmail(email)
      .then(() => {
        setState({
          ...INITIAL_STATE,
          success: {
            message: 'Reset password link has been sent to your email.',
          },
        });

        setLoading(false);

        setOpen(true);
      })
      .catch((error) => {
        setState({ ...state, error });
        setLoading(false);
      });

    event.preventDefault();
  };

  const onChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const { email, error } = state;
  const isInvalid = email === '';

  useEffect(() => {
    document.title = 'Forgot password - allPWD';
  }, []);

  return (
    <div className={classes.root}>
      <Box className={classes.header} textAlign="center">
        <Box className={classes.title}>
          <Typography component="h1" variant="h4">
            {t('translation:auth:forgot:title')}
          </Typography>
        </Box>

        <Box className={classes.subtitle} width={375}>
          <Typography component="h2" variant="body1">
            {t('translation:auth:forgot:description')}
          </Typography>
        </Box>
      </Box>

      <Container maxWidth="sm">
        <Box textAlign="center" mb={4}>
          {error && (
            <Typography component="p" variant="body1" className={classes.error}>
              {error.message}
            </Typography>
          )}
        </Box>

        <form className={classes.form} onSubmit={onSubmit}>
          <TextField
            type="text"
            id="email"
            autoComplete="false"
            inputProps={{
              autoComplete: 'false',
            }}
            name="email"
            value={email}
            onChange={onChange}
            placeholder={t('translation:auth:forgot:inputEmailPlaceholder')}
            required
            fullWidth
            InputProps={{
              classes: {
                input: classes.input,
              },
            }}
            style={{ marginBottom: 24 }}
          />

          <Box display="flex" flexDirection="column">
            <Box textAlign="center">
              <Button
                type="submit"
                color="primary"
                size="large"
                variant="contained"
                disabled={isInvalid}
                className={classes.submit}
              >
                {!isLoading ? t('translation:auth:forgot:resetButton') : <CircularProgress color="inherit" size={26} />}
              </Button>
            </Box>
          </Box>
        </form>

        <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
          <Alert variant="filled" severity="success">
            Password recovery link has been send to your email.
          </Alert>
        </Snackbar>
      </Container>
    </div>
  );
};

export default ForgotPassword;
