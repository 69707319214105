import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import AppleIcon from '@material-ui/icons/Apple';
import FacebookIcon from '@material-ui/icons/Facebook';
import PhoneIcon from '@material-ui/icons/Phone';
import TwitterIcon from '@material-ui/icons/Twitter';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(6, 0, 4, 0),
    display: 'none',
  },
  buttons: {
    margin: theme.spacing(0, 0, 4, 0),
  },
  button: {
    color: '#2E3542',
    border: '1px solid #2E3542',
    padding: theme.spacing(1.9),
    margin: theme.spacing(0, 0.75),
  },
}));

const AuthenticationTypes = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <Box className={classes.buttons}>
        <IconButton className={classes.button}>
          <PhoneIcon />
        </IconButton>

        <IconButton className={classes.button}>
          <AppleIcon />
        </IconButton>

        <IconButton className={classes.button}>
          <TwitterIcon />
        </IconButton>

        <IconButton className={classes.button}>
          <FacebookIcon />
        </IconButton>
      </Box>

      <Box textAlign="center">
        <Typography component="p" variant="body1">
          {t('translation:auth:signIn:useEmailLabel')}
        </Typography>
      </Box>
    </div>
  );
};

export default AuthenticationTypes;
