import { CircularProgress, Hidden } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import CssBaseline from '@material-ui/core/CssBaseline';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as RedSeif } from '../assets/red-seif.svg';
import { ReactComponent as VaultDisabled } from '../assets/vault-disabled.svg';
import ActivitySidebar from '../components/ActivityBar';
import BottomAdText from '../components/BottomAdText';
import LanguageDialog from '../components/LanguageDialog';
import LeftSidebarMobile from '../components/LeftSidebarMobile';
import MobileAppBar from '../components/MobileAppBar';
import MobileSearch from '../components/MobileSearch';
import PromotionDialog from '../components/PromotionDialog';
import SearchWidget from '../components/SearchWidget';
import LeftSidebar from '../components/SidebarMenu';
import VersionError from '../components/VersionError';
import { useApi } from '../hooks/useApi';
import { useAuth } from '../hooks/useAuth';
import { useRouter } from '../hooks/useRouter';
import { useVaultService } from '../hooks/useVaultService';

const drawerWidth = 304;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    // [theme.breakpoints.down('sm')]: {
    //   display: 'block',
    // },
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  appBar: {
    width: `calc(100% - 72px)`,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#FFF',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    width: '100%',
    flexGrow: 1,
    minHeight: '100vh',
    maxHeight: '100vh',
    overflow: 'auto',
    backgroundColor: '#F4F8FC',
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
    },
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  mainLoader: {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    width: '100%',
    height: '100%',
    position: 'fixed',
    zIndex: 100,
  },
  number: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 33,
    right: 6,
    width: 16,
    height: 16,
    borderRadius: 8,
    border: '1px solid #E41139',
    color: '#E41139',
    fontSize: 10,
    backgroundColor: 'white',
  },
}));

const MainLayout = (props) => {
  const classes = useStyles();
  const auth = useAuth();
  const vaultService = useVaultService();
  const api = useApi();
  const routes = useRouter();
  const { i18n } = useTranslation();
  const [isLoading] = useState(false);
  const [prices] = useState({});
  const [isDrawerOpened, setDrawerOpened] = useState(window.localStorage.getItem('sidebarOpened') === 'true');
  const [isActivityOpened, setActivityOpened] = useState(false);
  const [language, setLanguage] = useState(window.localStorage.getItem('i18nextLng') || 'en');
  const [isPromoShown, setPromoShown] = useState(true);
  const [isMobileSearchOpen, setIsMobileSearchOpen] = useState(false);

  const handleLanguageChange = (language) => {
    setLanguage(language);
  };

  const handleDrawerToggle = () => {
    window.localStorage.setItem('sidebarOpened', String(!isDrawerOpened));
    setDrawerOpened(!isDrawerOpened);
  };

  const handleActivityToggle = () => {
    setActivityOpened(!isActivityOpened);
  };

  useEffect(() => {
    document.title = 'Dashboard - allPWD';
    i18n.changeLanguage(language).then(() => {});
  }, [i18n, language]);

  const initUser = () => {
    api.getUser().then((response) => {
      if (response.data.data?.isPromoShown) {
        setPromoShown(true);
      } else {
        setPromoShown(false);
      }
    });
  };

  const openMobileSearch = () => {
    setIsMobileSearchOpen(true);
  };

  useEffect(() => {
    if (props.openRightBar) {
      setActivityOpened(true);
    }
  }, [props.openRightBar]);

  useEffect(() => {
    if (props.changePage) {
      routes.history.push('/web-sites-logins');
    }
  }, [props.changePage])

  useEffect(() => {
    if (auth.isReady) {
      initUser();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  useEffect(() => {
    let intervalId;

    const interval = () => {
      intervalId = setInterval(() => {
        clearInterval(intervalId);
        window.location.reload();
      }, 15 * 60000);
    };

    window.onclick = () => {
      clearInterval(intervalId);
      interval();
    };

    interval();
  }, []);

  return (
    <div className={classes.root}>
      <CssBaseline />

      <Hidden smDown>
        <AppBar
          position="absolute"
          color="default"
          elevation={1}
          className={clsx(classes.appBar, isDrawerOpened && classes.appBarShift)}
        >
          <Toolbar className={classes.toolbar}>
            <Box display="flex">
              <Box>
                <IconButton edge="start" color="inherit" onClick={handleDrawerToggle}>
                  {isDrawerOpened ? <MenuOpenIcon /> : <MenuIcon />}
                </IconButton>
              </Box>

              <Box display="flex" alignItems="center">
                <Box ml={1} mr={1}>
                  <SearchWidget openMobileSearch={openMobileSearch} />
                </Box>
              </Box>
              {/* {isLoading || !isPromoShown ? null : <PromotionDialog prices={prices} onClose={props.onClosePromo} />} */}
              {isLoading || isPromoShown ? null : <PromotionDialog prices={prices} onClose={props.onClosePromo} />}
            </Box>

            <Box display="flex" alignItems="center">
              <Box mr={1}>
                <LanguageDialog language={language} onChange={handleLanguageChange} />
              </Box>

              <IconButton
                onClick={handleActivityToggle}
                aria-label="vault"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
                id="vault-icon"
                style={{ position: 'relative' }}
              >
                {isActivityOpened ? <VaultDisabled /> : <RedSeif />}
                <div className={classes.number}>{vaultService.hiddenVaults.length + 1}</div>
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
      </Hidden>

      <Hidden mdUp>
        <LeftSidebarMobile isDrawerOpened={isDrawerOpened} setDrawerOpened={setDrawerOpened} />
      </Hidden>

      <Hidden mdUp>
        <MobileAppBar
          isDrawerOpened={isDrawerOpened}
          toggleSidebar={handleDrawerToggle}
          isActivityOpened={isActivityOpened}
          openMobileSearch={openMobileSearch}
          handleActivityToggle={handleActivityToggle}
          language={language}
          handleLanguageChange={handleLanguageChange}
        />
        {isLoading || isPromoShown ? null : <PromotionDialog prices={prices} />}
      </Hidden>

      <Hidden smDown>
        <LeftSidebar isDrawerOpened={isDrawerOpened} />
      </Hidden>

      <main className={classes.content} id="main-layout" style={{ overflow: 'auto' }}>
        <div className={classes.appBarSpacer} />
        {props.container}
      </main>

      <Hidden smDown>
        <ActivitySidebar mobile={false} isActivityOpened={isActivityOpened} />
      </Hidden>

      <Hidden mdUp>
        <ActivitySidebar mobile={true} isActivityOpened={isActivityOpened} />
      </Hidden>

      {vaultService.updateCurrentVaultLoading && (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          className={classes.mainLoader}
        >
          <CircularProgress color="inherit" size={40} />
        </Box>
      )}

      <BottomAdText
        left={isDrawerOpened ? '304px' : window.innerWidth >= 960 ? '72px' : '0px'}
        width={
          isDrawerOpened
            ? isActivityOpened
              ? 'calc(100% - 40px - 304px - 304px)'
              : 'calc(100% - 40px - 304px )'
            : window.innerWidth >= 960
            ? isActivityOpened
              ? 'calc(100% - 40px - 304px - 72px)'
              : 'calc(100% - 40px - 72px)'
            : isActivityOpened
            ? 'calc(100% - 40px - 304px)'
            : 'calc(100% - 40px)'
        }
      />

      {vaultService.showVersionError ? <VersionError onClose={() => vaultService.setShowVersionError(false)} /> : null}

      {isMobileSearchOpen && <MobileSearch onClose={() => setIsMobileSearchOpen(false)} />}
    </div>
  );
};

export default MainLayout;
