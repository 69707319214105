import axios from 'axios';
import bcrypt from 'bcryptjs';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import { cloneDeep, findIndex } from 'lodash';
import React, { createContext, useContext, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { SALT } from '../constants/encoding';
import * as ENDPOINTS from '../constants/endpoints';
import * as aes from '../helpers/aesCrypto';

const VaultServiceContext = createContext(null);

export function ProvideVaultService({ children }) {
  const api = useProvideVaultService();
  return <VaultServiceContext.Provider value={api}>{children}</VaultServiceContext.Provider>;
}

export const useVaultService = () => {
  return useContext(VaultServiceContext);
};

export const INITIAL_VAULT_STATE = {
  name: 'Main vault',
  ver: 1,
  upd: new Date().getTime(),
  rec_web: [],
  rec_file: [],
  rec_host: [],
  rec_bank: [],
  rec_crypt: [],
  rec_hw: [],
  rec_other: [],
  rec_contact: [],
};

export const INITIAL_HIDDEN_VAULT_STATE = {
  name: 'Hidden vault',
  ver: 0,
  upd: new Date().getTime(),
  rec_web: [],
  rec_file: [],
  rec_host: [],
  rec_bank: [],
  rec_crypt: [],
  rec_hw: [],
  rec_other: [],
  rec_contact: [],
};

function useProvideVaultService() {
  axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
  axios.defaults.headers['Accept'] = 'application/json';

  axios.interceptors.request.use(
    (config) => {
      return new Promise((resolve, reject) => {
        const user = firebase.auth().currentUser;
        if (user) {
          user.getIdToken()
            .then((token) => {
              config.headers['Authorization'] = `Bearer ${token}`;
              return resolve(config);
            })
            .catch((error) => {
              return reject(error);
            });
        } else {
          return reject('er');
        }
      });
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const [currentVault, setCurrentVault] = useState(null);
  const [mainVault, setMainVault] = useState(null);
  const [hiddenVaults, setHiddenVaults] = useState([]);
  const [vaults, setVaults] = useState([]);
  const [mainVaultPassword, setMainVaultPassword] = useState('');
  const [searchVaultText, setSearchVaultText] = useState('');
  const [searchType, setSearchType] = useState('mainfields');
  const [hiddenVaultsPassword, setHiddenVaultsPassword] = useState([]);
  const [updateCurrentVaultLoading, setUpdateCurrentVaultLoading] = useState(false);
  const [showVersionError, setShowVersionError] = useState(false);

  const getHash = (password) => {
    const user = firebase.auth().currentUser;
    if (user) {
      const str = `${user.uid}TimeSoft${password}`;
      const hash = bcrypt.hashSync(str, SALT);
      const result = hash.replace(SALT, '');
      return result;
    } else {
      alert('Error');
    }
  };

  const encrypt = async (data, password) => {
    const user = firebase.auth().currentUser;
    if (user) {
      const _pass = `${user.uid}TimeSoft${password}`;
      return aes.encrypt(data, _pass, user.uid);
    } else {
      return Promise.reject('ER');
    }
  };

  const decrypt = async (data, password) => {
    const user = firebase.auth().currentUser;
    if (user) {
      const _pass = `${user.uid}TimeSoft${password}`;
      return aes.decrypt(data, _pass, user.uid);
    } else {
      return Promise.reject(new Error('ER'));
    }
  };

  const getVault = (data, isMain = false) => {
    setUpdateCurrentVaultLoading(true);
    const passwordHash = getHash(data.password);
    return axios
      .post(ENDPOINTS.GET, { passwordHash }, { responseType: 'arraybuffer' })
      .then(async (res) => {
        let v = null,
          vs = null;
        if (res && res.status === 204 && !isMain) {
          return 'create_new_hidden_vault';
        }
        if (res && res.status === 204 && isMain) {
          const initialState = cloneDeep(INITIAL_VAULT_STATE);
          v = { ...initialState, upd: new Date().getTime(), id: uuidv4() };
          vs = [...vaults, v];
          createMainVault(data.password, v);
        }
        if (res && res.status === 200) {
          const decrypted = await decrypt(res.data, data.password);
          v = decrypted;
          vs = [...vaults, decrypted];
        }
        setVaults(vs);
        setCurrentVault(v);
        if (isMain) {
          setMainVault(v);
          setMainVaultPassword(data.password);
        } else {
          const decrypted = await decrypt(res.data, data.password);
          const _hiddenVaults = hiddenVaults.filter(item => item.id !== decrypted.id);
          setHiddenVaults([..._hiddenVaults, decrypted]);
          setHiddenVaultsPassword([
            ...hiddenVaultsPassword,
            {
              password: data.password,
              id: decrypted.id,
            },
          ]);
        }
        return v;
      })
      .finally(() => {
        setUpdateCurrentVaultLoading(false);
      });
  };

  const createMainVault = (password, file) => {
    const data = {
      password,
      file,
      version: 1,
    };
    setMainVaultPassword(password);
    updateVault(data);
  };

  const updateCurrentVault = (_newPassword) => {
    if (!updateCurrentVaultLoading) {
      setUpdateCurrentVaultLoading(true);
      let currentVaultPassword = '';
      if (mainVault && currentVault.id === mainVault.id) {
        currentVaultPassword = mainVaultPassword;
      } else {
        const hiddenVaultsPasswordObj = hiddenVaultsPassword.find((item) => currentVault.id === item.id);
        if (hiddenVaultsPasswordObj) {
          currentVaultPassword = hiddenVaultsPasswordObj.password;
        }
      }
      currentVault.ver++;
      const data = {
        password: _newPassword ? _newPassword : currentVaultPassword,
        file: { ...currentVault, upd: new Date().getTime() },
        version: currentVault.ver,
      };
      const _currentVault = cloneDeep(currentVault);
      setCurrentVault(_currentVault);
      const indexVault = findIndex(vaults, { id: _currentVault.id });
      vaults.splice(indexVault, 1, _currentVault);
      setVaults([...vaults]);
      if (mainVault && currentVault.id === mainVault.id) {
        setMainVault(_currentVault);
      } else {
        const indexHiddenVault = findIndex(hiddenVaults, { id: _currentVault.id });
        hiddenVaults.splice(indexHiddenVault, 1, _currentVault);
        setHiddenVaults([...hiddenVaults]);
      }
      updateVault(data);
    }
  };

  const updateVault = (data, newHiddenVault = null) => {
    if (newHiddenVault) {
      setVaults([...vaults, newHiddenVault]);
      setCurrentVault(newHiddenVault);
      setHiddenVaults([...hiddenVaults, newHiddenVault]);
      setHiddenVaultsPassword([
        ...hiddenVaultsPassword,
        {
          password: data.password,
          id: newHiddenVault.id,
        },
      ]);
      setUpdateCurrentVaultLoading(false);
      return;
    }
    const bodyFormData = new FormData();
    const newFile = { ...data.file };
    return encrypt(newFile, data.password).then((encrypted) => {
      const blob = new Blob([encrypted], { type: 'application/octet-stream' });
      const passwordHash = getHash(data.password);
      bodyFormData.append('passwordHash', passwordHash);
      bodyFormData.append('version', data.version);
      bodyFormData.append('file', blob);
      return (
        axios
          .post(ENDPOINTS.UPDATE_VAULT, bodyFormData, { headers: { 'Content-Type': 'multipart/form-data' } })
          .catch(() => {
            setShowVersionError(true);
            if (currentVault.id === mainVault.id) {
              return getVault({ password: data.password }, true);
            } else {
              return removeHiddenVault(currentVault)
                .then(() => getVault({ password: data.password }, false));
            }
          })
          // TODO BACK LOGIC
          .finally(() => setUpdateCurrentVaultLoading(false))
      );
    });
  };

  const removeHiddenVault = (vault) => {
    const _hiddenVaults = hiddenVaults.filter((item) => item.id !== vault.id);
    const _vaults = vaults.filter((item) => item.id !== vault.id);
    const _hiddenVaultsPassword = hiddenVaultsPassword.filter((item) => item.id !== vault.id);
    setHiddenVaults([..._hiddenVaults]);
    setHiddenVaultsPassword(_hiddenVaultsPassword);
    setVaults([..._vaults]);
    if (currentVault.id === vault.id) {
      setCurrentVault(mainVault);
    }
    return Promise.resolve();
  };

  const removeAllHiddenVault = () => {
    const _vaults = vaults.filter((item) => item.id === mainVault.id);
    const _hiddenVaultsPassword = [];
    setHiddenVaults([]);
    setHiddenVaultsPassword(_hiddenVaultsPassword);
    setVaults([..._vaults]);
    setCurrentVault(mainVault);
  };

  const selectVault = (vault) => {
    setCurrentVault(vault);
  };

  const resetVaultService = () => {
    setCurrentVault(null);
    setMainVault(null);
    setHiddenVaults([]);
    setVaults([]);
    setMainVaultPassword('');
    setHiddenVaultsPassword([]);
    setUpdateCurrentVaultLoading(false);
  };

  const updateCurrentVaultPassword = (currentPassword, newPassword) => {
    let found = null;
    if (currentVault.id === mainVault.id) {
      found = { password: mainVaultPassword };
    } else {
      found = hiddenVaultsPassword.find((item) => item.id === currentVault.id);
    }
    if (found && currentPassword !== found.password) {
      alert('Wrong password');
      return Promise.reject(new Error('Wrong password'));
    }
    setUpdateCurrentVaultLoading(true);

    if (currentVault.id === mainVault.id) {
      const user = firebase.auth().currentUser;
      if (user) {
        return user
          .updatePassword(newPassword)
          .then(() => {
            return updateVaultPasswordApi(currentPassword, newPassword);
          })
          .catch((error) => {
            console.log('error', error);
            alert('Firebase error');
            setUpdateCurrentVaultLoading(false);
            return Promise.reject(new Error('Firebase error'));
          });
      } else {
        alert('Auth fail');
        return Promise.reject(new Error('Auth fail'));
      }
    } else {
      return updateVaultPasswordApi(currentPassword, newPassword);
    }
  };

  const updateVaultPasswordApi = (currentPassword, newPassword) => {
    const bodyFormData = new FormData();
    const newFile = { ...currentVault, upd: new Date().getTime() };
    return encrypt(newFile, newPassword).then((encrypted) => {
      const blob = new Blob([encrypted], { type: 'application/octet-stream' });
      const oldPasswordHash = getHash(currentPassword);
      const newPasswordHash = getHash(newPassword);
      bodyFormData.append('oldPasswordHash', oldPasswordHash);
      bodyFormData.append('newPasswordHash', newPasswordHash);
      bodyFormData.append('version', currentVault.ver);
      bodyFormData.append('file', blob);
      return axios
        .post(ENDPOINTS.CHANGE_PASSWORD_VAULT, bodyFormData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then((res) => {
          if (res && res.status === 200) {
            if (currentVault.id === mainVault.id) {
              setMainVaultPassword(newPassword);
            } else {
              const _hiddenVaultsPassword = hiddenVaultsPassword.filter((item) => item.id !== currentVault.id);
              _hiddenVaultsPassword.push({ id: currentVault.id, password: newPassword });
              setHiddenVaultsPassword([..._hiddenVaultsPassword]);
            }
            return newPassword;
          }
        })
        .catch((err) => {
          alert('Server error');
          console.log(err.response);
          return Promise.reject(new Error('Server error'));
        })
        .finally(() => setUpdateCurrentVaultLoading(false));
    });
  };

  const deleteItem = (id, data) => {
    const bodyFormData = new FormData();
    const newFile = { ...data };

    let found = hiddenVaultsPassword.find((item) => item.id === currentVault.id);
    if (!found) {
      if (mainVault.id === currentVault.id) {
        found = { password: mainVaultPassword };
      } else {
        return Promise.reject(new Error('ER'));
      }
    }
    return encrypt(newFile, found.password).then((encrypted) => {
      const blob = new Blob([encrypted], { type: 'application/octet-stream' });
      bodyFormData.append('id', id);
      bodyFormData.append('file', blob);
      return axios.post(ENDPOINTS.DELETE_ITEM, bodyFormData, { headers: { 'Content-Type': 'multipart/form-data' } });
    });
  };

  const deleteCurrentVault = () => {
    const found = hiddenVaultsPassword.find((item) => item.id === currentVault.id);
    if (found) {
      const bodyFormData = new FormData();
      const passwordHash = getHash(found.password);
      bodyFormData.append('passwordHash', passwordHash);
      bodyFormData.append('version', currentVault.ver);
      return axios
        .post(ENDPOINTS.DELETE_VAULT, bodyFormData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then((res) => {
          if (res && res.status === 200) {
            removeHiddenVault(currentVault);
          }
        });
    }
  };

  const deleteSelectedItems = (selectedItems) => {
    const allPromises = selectedItems.map((item) => {
      return deleteItem(item.id, item.data);
    });
    return Promise.all(allPromises);
  };

  const recoverItem = (id) => {
    return axios.post(ENDPOINTS.RECOVER_ITEM, { id }, { responseType: 'arraybuffer' }).then(async (res) => {
      let found = hiddenVaultsPassword.find((item) => item.id === currentVault.id);
      if (!found) {
        if (mainVault.id === currentVault.id) {
          found = { password: mainVaultPassword };
        } else {
          return Promise.reject(new Error('ER'));
        }
      }
      const decrypted = await decrypt(res.data, found.password);
      res.data = decrypted;
      return res;
    });
  };

  const eraseDeleteItem = (id) => {
    return axios.post(ENDPOINTS.ERASE_DELETE_ITEM, { id }).then((res) => {
      console.log('res', res);
    });
  };

  const isMainVault = () => {
    return currentVault && mainVault && currentVault.id === mainVault.id;
  };

  return {
    vaults,
    mainVault,
    currentVault,
    hiddenVaults,
    mainVaultPassword,
    hiddenVaultsPassword,
    updateCurrentVaultLoading,
    searchVaultText,
    searchType,
    showVersionError,
    getVault,
    updateVault,
    removeHiddenVault,
    removeAllHiddenVault,
    selectVault,
    updateCurrentVault,
    resetVaultService,
    deleteItem,
    deleteSelectedItems,
    recoverItem,
    eraseDeleteItem,
    deleteCurrentVault,
    isMainVault,
    updateCurrentVaultPassword,
    setSearchVaultText,
    setSearchType,
    setShowVersionError
  };
}
