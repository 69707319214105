/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  IconButton,
  InputAdornment,
  Modal,
  TextareaAutosize,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Close from '@material-ui/icons/Close';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Crown } from '../assets/crown.svg';
import { useApi } from '../hooks/useApi';
import { useVaultService } from '../hooks/useVaultService';

const useStyles = makeStyles(() => ({
  root: {
    width: window.innerWidth > 560 ? '50%' : '100%',
    minHeight: window.innerWidth > 560 ? 0 : '100%',
    maxWidth: 527,
    minWidth: 317,
    background: '#fff',
    margin: '0 auto',
    borderRadius: window.innerWidth > 560 ? 10 : 0,
    boxShadow: '0px 1px 7px rgba(0, 0, 0, 0.15)',
    padding: 25,
  },
  modal: {
    overflow: 'auto',
    paddingTop: window.innerWidth > 560 ? '10vh' : 0,
    paddingBottom: window.innerWidth > 560 ? '10vh' : 0,
  },
  close: {
    color: '#C1C4C9',
    marginTop: 3,
    width: '100%',
    cursor: 'pointer',
  },
  title: {
    color: '#A6AAB0',
    marginTop: -20,
    fontSize: 18,
    marginLeft: 40,
    marginRight: 40,
    textAlign: 'center',
  },
  description1: {
    color: '#2E3542',
    marginTop: 37,
    fontSize: 18,
    marginLeft: 40,
    marginRight: 40,
    textAlign: 'center',
  },
  description2: {
    color: '#A6AAB0',
    marginTop: 23,
    fontSize: 14,
    marginLeft: 40,
    marginRight: 40,
    textAlign: 'center',
  },
  passwordField: {
    width: '100%',
  },
  input: {},
  error: {
    fontSize: 16,
    color: '#E41139',
    textAlign: 'center',
  },
  capsLock: {
    fontSize: 16,
    color: '#af6100',
    textAlign: 'center',
  },
  card: {
    width: '100%',
  },
  label: {
    padding: '16px 0 0 16px',
  },
  textarea: {
    width: '100%',
    borderRadius: 10,
    background: '#F4F8FC',
    color: '#2E3542',
    border: '1px solid #F4F8FC',
    padding: '17px 22px',
    fontSize: 16,
    fontFamily: 'Roboto',
    '&:focus': {
      outline: 'none',
    },
    '&:disabled': {
      background: '#c4ced8',
    },
    '&:placeholder': {
      outline: 'none',
      color: '#A6AAB0',
    },
  },
  secondary: {
    fontSize: 14,
    marginBottom: 5,
  },
}));

const EditVaultName = (props) => {
  const classes = useStyles();
  const vaultService = useVaultService();
  const api = useApi();
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [error] = useState('');
  const { currentVault, updateCurrentVault, updateCurrentVaultPassword } = vaultService;
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [isPlanFree, setIsPlanFree] = useState(true);
  const [newPassword, setNewPassword] = useState('');
  const { onClose } = props;

  const handleClickShowCurrentPassword = () => setShowCurrentPassword(!showCurrentPassword);
  const handleMouseDownCurrentPassword = () => setShowCurrentPassword(!showCurrentPassword);
  const handleClickShowNewPassword = () => setShowNewPassword(!showNewPassword);
  const handleMouseDownNewPassword = () => setShowNewPassword(!showNewPassword);

  const onChange = (event) => {
    setName(event.target.value);
  };

  const onChangeCurrentPassword = (event) => {
    setCurrentPassword(event.target.value);
  };

  const onChangeNewPassword = (event) => {
    setNewPassword(event.target.value);
  };

  const init = () => {
    setName(currentVault.name);
    api.getUser().then((response) => {
      setIsPlanFree(response.data.plan === 'free');
    });
  };

  const checkName = (_newPassword) => {
    if (currentVault.name !== name.trim()) {
      currentVault.name = name.trim();
      updateCurrentVault(_newPassword);
    }
  };

  const onSubmit = () => {
    setLoading(true);
    if (
      !!currentPassword &&
      !!newPassword &&
      currentPassword.split(' ').join('').length >= 4 &&
      newPassword.split(' ').join('').length >= 4 &&
      currentPassword !== newPassword
    ) {
      updateCurrentVaultPassword(currentPassword.split(' ').join(''), newPassword.split(' ').join(''))
        .then((_newPassword) => {
          if (_newPassword) {
            checkName(_newPassword);
            onClose();
          }
        })
        .catch((err) => {
          console.log('err', err);
        })
        .finally(() => setLoading(false));
    } else {
      checkName();
      setLoading(false);
      onClose();
    }
  };

  useEffect(() => {
    init();
  }, []);

  const isInvalid =
    name.trim().length < 4 ||
    (currentPassword && newPassword
      ? !(
          currentPassword.split(' ').join('').length >= 4 &&
          newPassword.split(' ').join('').length >= 4 &&
          currentPassword !== newPassword
        )
      : false);

  return (
    <Modal open={true} className={classes.modal} onClose={() => onClose()}>
      <Box
        className={classes.root}
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Box
          onClick={() => onClose()}
          className={classes.close}
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Close />
        </Box>
        <Box
          className={classes.title}
          display="flex"
          flexDirection="columm"
          justifyContent="center"
          alignItems="center"
        >
          {t('translation:commonPages:vaultSettings')}
        </Box>
        <Card style={{ marginTop: 40 }} className={classes.card}>
          <Typography className={classes.label} component="p">
            {t('translation:commonPages:changeVaultName')}
          </Typography>
          <CardContent>
            <Box className={classes.passwordField}>
              <TextField
                type="text"
                id="name"
                name="name"
                autoComplete="false"
                value={name}
                inputProps={{
                  maxLength: 50,
                  autoComplete: 'false',
                }}
                onChange={onChange}
                placeholder={t('translation:commonPages:vaultName')}
                label={t('translation:commonPages:vaultName')}
                required
                fullWidth
              />
              {error && <Box className={classes.error}>{error}</Box>}
            </Box>
          </CardContent>
        </Card>
        <Card style={{ marginTop: 20 }} className={classes.card}>
          <Box
            display="flex"
            justifyContent="space-between"
            style={{ padding: 16, flexDirection: window.innerWidth > 560 ? 'row' : 'column' }}
          >
            <Box display="flex" direction="row" justifyContent="flex-start" alignItems="center">
              <Typography component="p" style={{ marginRight: 10 }}>
                {t('translation:commonPages:premiumFeatures')}
              </Typography>
              <Crown width={35} height={35} style={{ marginTop: -5 }} />
            </Box>
            {isPlanFree && (
              <Button variant="contained" color="primary" style={{ marginTop: window.innerWidth > 560 ? 0 : 20 }}>
                {t('translation:commonPages:buyPremium')}
              </Button>
            )}
          </Box>
          <CardContent>
            <Box className={classes.passwordField}>
              <Typography className={classes.secondary} component="p">
                {t('translation:commonPages:setLabel1')}
              </Typography>
              <TextareaAutosize
                aria-label="minimum height"
                rowsMin={2}
                disabled={isPlanFree}
                maxLength={264}
                label={t('translation:commonPages:setMessText1')}
                placeholder={t('translation:commonPages:setMessText1')}
                className={classes.textarea}
                id="desc1"
                name="desc1"
                style={{ marginBottom: 24 }}
              />

              <Typography className={classes.secondary} component="p">
                {t('translation:commonPages:setLabel2')}
              </Typography>
              <TextField
                type="text"
                id="name1"
                name="name1"
                autoComplete="false"
                disabled={isPlanFree}
                inputProps={{
                  maxLength: 50,
                  autoComplete: 'false',
                }}
                placeholder={t('translation:commonPages:numberOfDay')}
                label={t('translation:commonPages:numberOfDay')}
                required
                fullWidth
                style={{ marginBottom: 24 }}
              />

              <Typography className={classes.secondary} component="p">
                {t('translation:commonPages:setLabel3')}
              </Typography>
              <TextField
                type="text"
                id="name2"
                name="name2"
                autoComplete="false"
                disabled={isPlanFree}
                inputProps={{
                  maxLength: 50,
                  autoComplete: 'false',
                }}
                placeholder={t('translation:commonPages:emailOr')}
                label={t('translation:commonPages:emailOr')}
                required
                fullWidth
                style={{ marginBottom: 24 }}
              />

              <TextareaAutosize
                aria-label="minimum height"
                rowsMin={2}
                maxLength={264}
                label={t('translation:commonPages:setMessText1')}
                disabled={isPlanFree}
                placeholder={t('translation:commonPages:setMessText1')}
                className={classes.textarea}
                id="desc1"
                name="desc1"
                style={{ marginBottom: 24 }}
              />
            </Box>
          </CardContent>
        </Card>
        <Card style={{ marginTop: 20, marginBottom: 30 }} className={classes.card}>
          <Typography className={classes.label} component="p">
            {t('translation:commonPages:changeVaultPassword')}
          </Typography>
          <CardContent>
            <Box className={classes.passwordField}>
              <TextField
                type="text"
                style={{ '-webkit-text-security': showCurrentPassword ? 'none' : 'disc', marginBottom: 24 }}
                id="currentpassword"
                name="currentpassword"
                autoComplete="false"
                value={currentPassword}
                onChange={onChangeCurrentPassword}
                inputProps={{
                  maxLength: 64,
                  autoComplete: 'false',
                }}
                label={t('translation:commonPages:currentPassword')}
                placeholder={t('translation:auth:signIn:inputPasswordPlaceholder')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClickShowCurrentPassword} onMouseDown={handleMouseDownCurrentPassword}>
                        {showCurrentPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                  classes: {
                    input: classes.input,
                  },
                }}
                required
                fullWidth
              />
              <TextField
                type="text"
                style={{ '-webkit-text-security': showNewPassword ? 'none' : 'disc' }}
                id="newpassword"
                name="newpassword"
                autoComplete="false"
                value={newPassword}
                onChange={onChangeNewPassword}
                inputProps={{
                  maxLength: 64,
                  autoComplete: 'false',
                }}
                label={t('translation:commonPages:newPassword')}
                placeholder={t('translation:auth:signIn:inputPasswordPlaceholder')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClickShowNewPassword} onMouseDown={handleMouseDownNewPassword}>
                        {showNewPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                  classes: {
                    input: classes.input,
                  },
                }}
                required
                fullWidth
              />
            </Box>
          </CardContent>
        </Card>
        <Box
          className={classes.passwordField}
          display="flex"
          flexDirection="columm"
          justifyContent="center"
          alignItems="center"
        >
          <Button style={{ marginRight: 20 }} onClick={() => onClose()} variant="outlined" color="primary">
            {t('translation:commonPages:cancel')}
          </Button>
          <Button variant="contained" disabled={isInvalid || isLoading} onClick={() => onSubmit()} color="primary">
            {isLoading ? <CircularProgress color="inherit" size={26} /> : t('translation:commonPages:save')}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default EditVaultName;
