/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Modal, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Close from '@material-ui/icons/Close';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useVaultService } from '../hooks/useVaultService';

const useStyles = makeStyles(() => ({
  root: {
    width: window.innerWidth > 560 ? '50%' : '100%',
    minHeight: window.innerWidth > 560 ? 0 : '100%',
    maxWidth: 527,
    minWidth: 317,
    background: '#fff',
    margin: '0 auto',
    borderRadius: window.innerWidth > 560 ? 10 : 0,
    boxShadow: '0px 1px 7px rgba(0, 0, 0, 0.15)',
    padding: 25,
  },
  modal: {
    overflow: 'auto',
    paddingTop: window.innerWidth > 560 ? '10vh' : 0,
    paddingBottom: window.innerWidth > 560 ? '10vh' : 0,
  },
  close: {
    color: '#C1C4C9',
    marginTop: 3,
    width: '100%',
    cursor: 'pointer',
  },
  title: {
    color: '#A6AAB0',
    marginTop: -20,
    fontSize: 18,
    marginLeft: 40,
    marginRight: 40,
    textAlign: 'center',
  },
  description1: {
    color: '#2E3542',
    marginTop: 37,
    fontSize: 18,
    marginLeft: 40,
    marginRight: 40,
    textAlign: 'center',
  },
  warning: {
    color: '#2E3542',
    marginTop: 37,
    fontSize: 16,
  },
  description2: {
    color: '#A6AAB0',
    marginTop: 23,
    fontSize: 14,
    marginLeft: 40,
    marginRight: 40,
    textAlign: 'center',
  },
  passwordField: {
    marginTop: 60,
    paddingLeft: window.innerWidth > 560 ? 40 : 0,
    paddingRight: window.innerWidth > 560 ? 40 : 0,
    width: '100%',
  },
  input: {},
  error: {
    fontSize: 16,
    color: '#E41139',
    textAlign: 'center',
  },
}));

const DeleteVault = (props) => {
  const classes = useStyles();
  const vaultService = useVaultService();
  const { t } = useTranslation();
  const [isLoading] = useState(false);
  const [deleteWord, setDeleteWord] = useState('');
  const [error] = useState('');
  const { deleteCurrentVault } = vaultService;
  const { onClose } = props;

  const onChange = (event) => {
    setDeleteWord(event.target.value);
  };

  const init = () => {};

  const onSubmit = () => {
    deleteCurrentVault();
    onClose();
  };

  useEffect(() => {
    init();
  }, []);

  const isInvalid = deleteWord.trim() !== 'DELETE';

  return (
    <Modal open={true} className={classes.modal} onClose={() => onClose()}>
      <Box
        className={classes.root}
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Box
          onClick={() => onClose()}
          className={classes.close}
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Close />
        </Box>
        <Box
          className={classes.title}
          display="flex"
          flexDirection="columm"
          justifyContent="center"
          alignItems="center"
        >
          {t('translation:commonPages:delVault')}
        </Box>
        <Box
          className={classes.warning}
          display="flex"
          flexDirection="columm"
          justifyContent="center"
          alignItems="center"
        >
          {t('translation:commonPages:warn')}
        </Box>
        <Box
          className={classes.description1}
          display="flex"
          flexDirection="columm"
          justifyContent="center"
          alignItems="center"
        >
          {t('translation:commonPages:delVaultDesc')}
        </Box>
        <Box className={classes.passwordField}>
          <TextField
            type="text"
            id="deleteWord"
            name="deleteWord"
            autoComplete="false"
            value={deleteWord}
            label={t('translation:commonPages:delVaultType')}
            inputProps={{
              maxLength: 50,
              autoComplete: 'false',
            }}
            onChange={onChange}
            placeholder="DELETE"
            required
            fullWidth
          />
          {error && <Box className={classes.error}>{error}</Box>}
        </Box>
        <Box
          className={classes.passwordField}
          display="flex"
          flexDirection="columm"
          justifyContent="center"
          alignItems="center"
        >
          <Button style={{ marginRight: 20 }} onClick={() => onClose()} variant="outlined" color="primary">
            {t('translation:commonPages:cancel')}
          </Button>
          <Button variant="contained" disabled={isInvalid || isLoading} onClick={() => onSubmit()} color="primary">
            {t('translation:commonPages:delete')}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default DeleteVault;
