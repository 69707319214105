import { ThemeProvider } from '@material-ui/core/styles';
import 'fontsource-roboto';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './containers/App';
import { ProvideApi } from './hooks/useApi';
import { ProvideAuth } from './hooks/useAuth';
import { ProvideSteps } from './hooks/useSteps';
import { ProvideVaultService } from './hooks/useVaultService';
import './i18n';
import reportWebVitals from './reportWebVitals';
import theme from './themes/light.theme';

ReactDOM.render(
  <ProvideAuth>
    <ProvideApi>
      <ProvideSteps>
        <ProvideVaultService>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </ProvideVaultService>
      </ProvideSteps>
    </ProvideApi>
  </ProvideAuth>,
  document.getElementById('root')
);

reportWebVitals();

console.log(`(c) TIMESOFT, Inc, allPWD.com password vault`);
