import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Close from '@material-ui/icons/Close';
import React from 'react';
import DisabledVault from '../assets/disabled-vault.png';
import OpenVault from '../assets/openvault.png';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: 55,
    borderRadius: 4,
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.14)',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: '3px 4px 8px rgba(0, 0, 0, 0.14)',
    },
  },
  rootWrap: {
    width: '100%',
    position: 'relative',
  },
  indicator: {
    background: '#41EA25',
    width: 6,
    height: 6,
    borderRadius: 3,
  },
  text: {
    fontSize: 16,
    color: '#2E3542',
    fontWeight: 700,
  },
  leftIndicator: {
    height: '100%',
    width: 8,
    borderRadius: '4px 0 0 4px',
  },
  close: {
    position: 'absolute',
    right: -9,
    top: -9,
    width: 24,
    height: 25,
    background: 'rgba(46, 53, 66, 0.2)',
    borderRadius: 12,
    cursor: 'pointer',
    color: '#FFFFFF',
    zIndex: 10,
  },
}));

const VaultItem = (props) => {
  const classes = useStyles();
  const { isActive, isMain, name, onSelect, vault, onClose } = props;

  return (
    <Box className={classes.rootWrap} mb={1.8}>
      {!isMain && (
        <Box
          onClick={onClose}
          className={classes.close}
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
        >
          <Close style={{ fontSize: 14 }} />
        </Box>
      )}
      <Box
        className={classes.root}
        style={{ background: isActive ? '#2E3542' : '#fff' }}
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
        alignItems="center"
        onClick={() => onSelect(vault)}
      >
        <Box className={classes.leftIndicator} style={{ background: '#2E3542' }}></Box>
        <Box ml={1.6} mr={isActive ? 0 : 2.4}>
          {isActive ? <img alt="icon" src={OpenVault} width={35} /> : <img alt="icon" src={DisabledVault} width={35} />}
        </Box>
        {isActive && <Box ml={1.2} mr={0.6} className={classes.indicator}></Box>}
        <Typography component="p" className={classes.text} style={{ color: isActive ? '#fff' : '#2E3542' }}>
          {name}
        </Typography>
      </Box>
    </Box>
  );
};

export default VaultItem;
