import { Box, Card, CardContent, Checkbox, IconButton, makeStyles, Typography } from '@material-ui/core';
import DeleteOutlineOutlined from '@material-ui/icons/DeleteOutlineOutlined';
import EditOutlined from '@material-ui/icons/EditOutlined';
import Favorite from '@material-ui/icons/Favorite';
import FavoriteBorderOutlined from '@material-ui/icons/FavoriteBorderOutlined';
import History from '@material-ui/icons/History';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CopyToClipboard from '../../components/CopyToClipboard';

const useStyles = makeStyles(() => ({
  root: { marginRight: 20, marginBottom: 20, width: window.innerWidth > 560 ? 'auto' : '90vw' },
  icon: { padding: 9, color: '#A6AAB0', width: 42 },
  label: { fontSize: 12, color: '#A6AAB0' },
  textLink: { color: '#E41139', fontSize: 16, textDecoration: 'underline' },
  text: { color: '#2E3542', fontSize: 16 },
  valueWrap: { maxWidth: '75%', wordBreak: 'break-all', },
  row: { paddingBottom: 10, marginBottom: 10, borderBottom: '1px solid rgba(0, 0, 0, 0.1)' },
  header: { padding: '23px 26px', background: '#2E3542' },
  checkbox: { color: '#A6AAB0' },
  textRed: { color: '#E41139', fontSize: 12, fontWeight: 'normal' },
}));

const CardView = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    data,
    showEditModal,
    handleClickFavorite,
    currentRecHW,
    showViewModal,
    checked,
    selectCheckedValue,
    isDeleteMode,
    recoverDeletedItem,
    deleteOneRow,
  } = props;
  const [showPassword, setShowPassword] = useState(false);
  const [showPIN, setShowPIN] = useState(false);

  let end = null,
    current = null;
  if (isDeleteMode) {
    end = moment().subtract(30, 'days');
    current = moment(data.del);
  }

  return (
    <Card className={classes.root}>
      <Box display="flex" flexDirection="row" justifyContent="space-between" className={classes.header}>
        {!isDeleteMode && (
          <Checkbox
            value="checked"
            className={classes.checkbox}
            onChange={selectCheckedValue}
            checked={checked && checked.checked}
          />
        )}
        <Box display="flex" flexDirection="row" justifyContent="flex-end" onDoubleClick={() => showViewModal(data.id)}>
          {!isDeleteMode ? (
            <>
              <IconButton
                className={classes.icon}
                aria-label="view list"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
                style={{ color: currentRecHW.fav ? '#E41139' : '#A6AAB0' }}
                onClick={() => handleClickFavorite(data.id)}
              >
                {currentRecHW.fav ? <Favorite /> : <FavoriteBorderOutlined />}
              </IconButton>
              <IconButton
                className={classes.icon}
                aria-label="view list"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
                onClick={() => {
                  showEditModal(data.id);
                }}
              >
                <EditOutlined />
              </IconButton>
              <IconButton
                className={classes.icon}
                aria-label="view list"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
                onClick={() => {
                  deleteOneRow();
                }}
              >
                <DeleteOutlineOutlined />
              </IconButton>
            </>
          ) : (
            <IconButton
              className={classes.icon}
              aria-label="view list"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              color="inherit"
              onClick={() => recoverDeletedItem()}
            >
              <History />
            </IconButton>
          )}
        </Box>
      </Box>
      <CardContent>
        <Box display="flex" flexDirection="column">
          <Box
            className={classes.row}
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="flex-end"
          >
            <Box
              className={classes.valueWrap}
              display="flex"
              flexDirection="column"
              justifyContent="flex-end"
              alignItems="flex-start"
            >
              <Typography className={classes.label} component="p">
                {t('translation:commonPages:name')}
              </Typography>
              <Typography component="p" className={classes.text}>
                {data.name}
              </Typography>
            </Box>
            <CopyToClipboard text={data.name} />
          </Box>

          {!isDeleteMode && (
            <Box
              className={classes.row}
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="flex-end"
            >
              <Box
                className={classes.valueWrap}
                display="flex"
                flexDirection="column"
                justifyContent="flex-end"
                alignItems="flex-start"
              >
                <Typography className={classes.label} component="p">
                  {t('translation:commonPages:password')}
                </Typography>
                <Typography component="p" className={classes.text}>
                  {showPassword ? data.password : '******'}
                </Typography>
              </Box>
              <Box display="flex" flexDirection="row" justifyContent="flex-end" alignItems="flex-end">
                <IconButton
                  className={classes.icon}
                  aria-label="view list"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  color="inherit"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
                <CopyToClipboard text={data.password} />
              </Box>
            </Box>
          )}

          {!isDeleteMode && (
            <Box
              className={classes.row}
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="flex-end"
            >
              <Box
                className={classes.valueWrap}
                display="flex"
                flexDirection="column"
                justifyContent="flex-end"
                alignItems="flex-start"
              >
                <Typography className={classes.label} component="p">
                  PIN
                </Typography>
                <Typography component="p" className={classes.text}>
                  {showPIN ? data.pin : '******'}
                </Typography>
              </Box>
              <Box display="flex" flexDirection="row" justifyContent="flex-end" alignItems="flex-end">
                <IconButton
                  className={classes.icon}
                  aria-label="view list"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  color="inherit"
                  onClick={() => setShowPIN(!showPIN)}
                >
                  {showPIN ? <Visibility /> : <VisibilityOff />}
                </IconButton>
                <CopyToClipboard text={data.pin} />
              </Box>
            </Box>
          )}

          {!isDeleteMode && (
            <Box
              className={classes.row}
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="flex-end"
            >
              <Box
                className={classes.valueWrap}
                display="flex"
                flexDirection="column"
                justifyContent="flex-end"
                alignItems="flex-start"
              >
                <Typography className={classes.label} component="p">
                  {t('translation:commonPages:login')}
                </Typography>
                <Typography component="p" className={classes.text}>
                  {data.login}
                </Typography>
              </Box>
              <CopyToClipboard text={data.login} />
            </Box>
          )}

          {isDeleteMode && (
            <Box
              className={classes.row}
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="flex-end"
              style={{ borderBottom: 'none' }}
            >
              <Box
                className={classes.valueWrap}
                display="flex"
                flexDirection="column"
                justifyContent="flex-end"
                alignItems="flex-start"
              >
                <Typography className={classes.label} component="p">
                  {t('translation:commonPages:deldate')}
                </Typography>
                <Typography component="p" className={classes.text}>
                  {moment(data.del).fromNow()}
                </Typography>
                <Typography component="p" className={classes.textRed}>
                  {current.diff(end, 'days') + ' days left before permanent deletion'}
                </Typography>
              </Box>
            </Box>
          )}

          {!isDeleteMode && (
            <Box
              className={classes.row}
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="flex-end"
              style={{ borderBottom: 'none' }}
            >
              <Box
                className={classes.valueWrap}
                display="flex"
                flexDirection="column"
                justifyContent="flex-end"
                alignItems="flex-start"
              >
                <Typography className={classes.label} component="p">
                  {t('translation:commonPages:desc')}
                </Typography>
                <Typography component="p" className={classes.text}>
                  {data.description}
                </Typography>
              </Box>
              <CopyToClipboard text={data.description} />
            </Box>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default CardView;
