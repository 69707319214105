import { Box, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import AddFile from '../../assets/add-file.png';

const useStyles = makeStyles((theme) => ({
  notFoundWrap: {
    width: '100%',
    height: 'calc(100vh - 200px)',
  },
}));

const NotFound = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box
      className={classes.notFoundWrap}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <img alt="icon" src={AddFile} style={{ opacity: 0.3 }} />
      <Typography
        component="p"
        style={{ fontWeight: 500, fontSize: 26, color: '#2E3542', opacity: 0.3, textAlign: 'center', marginTop: 60 }}
      >
        {t('translation:commonPages:nfFilePwd')} <br /> {t('translation:commonPages:nfFilePwdCr')}
      </Typography>
    </Box>
  );
};

export default NotFound;
