import { Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  btn: {
    textTransform: 'none',
    paddingLeft: 20,
    paddingRight: 20,
    marginRight: 10,
    padding: 5,
    opacity: 0.4,
    border: '1px solid transparent',
    '&:hover': {
      opacity: 1,
    },
    '&.active': {
      opacity: 1,
      border: '1px solid #2E3542',
    },
  },
}));

const TableSwitcherBtns = (props) => {
  const classes = useStyles();
  const { onChangeTableMode } = props;
  const [view, setView] = useState('allitems');
  const { t } = useTranslation();

  const init = () => {
    onChangeTableMode(view);
  };

  useEffect(() => {
    init();
  });

  return (
    <Box display="flex" alignItems="center" flexDirection="row" justifyContent="flex-end" flexGrow={10}>
      <Button
        color="primary"
        size="small"
        className={classNames(classes.btn, view === 'allitems' ? 'active' : '')}
        onClick={() => {
          setView('allitems');
          onChangeTableMode('allitems');
        }}
      >
        {t('translation:commonPages:allItems')}
      </Button>
      <Button
        color="primary"
        size="small"
        className={classNames(classes.btn, view === 'favorites' ? 'active' : '')}
        onClick={() => {
          setView('favorites');
          onChangeTableMode('favorites');
        }}
      >
        {t('translation:commonPages:favorites')}
      </Button>
      <Button
        color="primary"
        size="small"
        className={classNames(classes.btn, view === 'deleted' ? 'active' : '')}
        onClick={() => {
          setView('deleted');
          onChangeTableMode('deleted');
        }}
      >
        {t('translation:commonPages:deleted')}
      </Button>
    </Box>
  );
};

export default TableSwitcherBtns;
