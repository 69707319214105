import { AppBar, Hidden, IconButton, Toolbar } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DisabledVault from '../assets/disabled-vault.png';
import RedVault from '../assets/red-vault.png';
import { useVaultService } from '../hooks/useVaultService';
import LanguageDialog from './LanguageDialog';
import Logo from './Logo';
import SearchWidget from './SearchWidget';
import MenuIcon from '@material-ui/icons/Menu';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#fff',
  },
  number: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 33,
    right: 6,
    width: 16,
    height: 16,
    borderRadius: 8,
    border: '1px solid #E41139',
    color: '#E41139',
    fontSize: 10,
    backgroundColor: 'white',
  },
}));

const MobileAppBar = (props) => {
  const classes = useStyles();
  const { i18n } = useTranslation();
  const vaultService = useVaultService();
  const [language, setLanguage] = useState(window.localStorage.getItem('i18nextLng') || 'en');

  useEffect(() => {
    document.title = 'Dashboard - allPWD';
    i18n.changeLanguage(language).then(() => {});
  }, [i18n, language]);

  const handleLanguageChange = (language) => {
    setLanguage(language);
  };

  return (
    <div className={classes.root}>
      <AppBar position="fixed" elevation={1}>
        <Toolbar className={classes.toolbar}>
          <Box display="flex" alignItems="center">
            <IconButton style={{ color: 'black' }} edge="start" color="inherit" onClick={() => props.toggleSidebar()}>
              {props.isDrawerOpened ? <MenuOpenIcon /> : <MenuIcon />}
            </IconButton>
            <IconButton edge="start" className={classes.menuButton} color="inherit">
              <Logo type={'inverted-minimized'} />
            </IconButton>
          </Box>

          <Hidden xsDown>
            <Box display="flex" alignItems="center">
              <Box ml={1} mr={1}>
                <SearchWidget openMobileSearch={props.openMobileSearch} />
              </Box>
            </Box>
          </Hidden>

          <Box display="flex" alignItems="center">
            <Hidden smUp>
              <Box display="flex" alignItems="center">
                <Box mr={1}>
                  <SearchWidget openMobileSearch={props.openMobileSearch} />
                </Box>
              </Box>
            </Hidden>
            <Box mr={1}>
              <LanguageDialog language={language} onChange={handleLanguageChange} />
            </Box>
            <IconButton
              color="inherit"
              aria-label="vault"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              style={{ position: 'relative' }}
              onClick={() => props.handleActivityToggle()}
            >
              {props.isActivityOpened ? (
                <img alt="icon" src={DisabledVault} width={35} />
              ) : (
                <img alt="icon" src={RedVault} width={35} />
              )}
              <div className={classes.number}>{vaultService.hiddenVaults.length + 1}</div>
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default MobileAppBar;
