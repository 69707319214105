/* eslint-disable react-hooks/exhaustive-deps */
import { faBitcoin, faEthereum } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, CircularProgress } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TooltipIcon from '../../components/TooltipIcon';
import { convertBytesToMegaBytes } from '../../helpers/converter';
import { useApi } from '../../hooks/useApi';
import { useAuth } from '../../hooks/useAuth';
import { useRouter } from '../../hooks/useRouter';
import { useSteps } from '../../hooks/useSteps';
import firebase from 'firebase/app'; // for the whole Firebase Web App
import 'firebase/auth'; // for Firebase Authentication

const useStyles = makeStyles((theme) => ({
  listItemText: {
    fontSize: 24,
    color: '#627087',
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
  },
  listItemTextSummary: {
    fontSize: 24,
    fontWeight: 500,
    color: '#2E3542',
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
  },
  listItemSecondaryAction: {
    fontSize: 24,
    color: '#627087',
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
  },
  listItemSecondaryActionSummary: {
    fontSize: 24,
    fontWeight: 500,
    color: '#2E3542',
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
  },
  formControlLabel: {
    marginRight: theme.spacing(6),
  },
  formControlLabelText: {
    fontSize: 24,
  },
}));

const PaySummary = (props) => {
  const router = useRouter();
  const api = useApi();
  const auth = useAuth();
  const { t } = useTranslation();
  const classes = useStyles();
  const { capsule, setCapsule } = useSteps();
  const [data, setData] = useState({ capsule: {}, user: {} });
  const [isLoading, setLoading] = useState(true);

  const [paymentMethod, setPaymentMethod] = useState(capsule?.paymentMethod || 'BANK');

  const storagePeriodDate = moment(new Date()).add(data.capsule?.storePeriod, 'months').format('D MMMM yyyy');
  const totalFee = data.capsule?.capsuleFee + data.capsule?.courierFee;
  const topUpAmount =
    Math.abs(data.user.balance - totalFee) > 0 && Math.abs(data.user.balance - totalFee) < 1
      ? Number(1)
      : Math.abs(data.user.balance - totalFee);

  const capsuleId = router.query.id;

  const handleChangePaymentMethod = (event) => {
    setPaymentMethod(event.target.value);
    setCapsule({ ...capsule, paymentMethod: event.target.value });
  };

  const getCourierServiceTitle = (courierService) => {
    switch (courierService) {
      case 'dhl':
        return 'DHL Same Day Jetline';
      case 'fedex':
        return 'FedEx International Next Flight';
      case 'ups':
        return 'UPS Express Critical';
      default:
        return 'Unknown courier service';
    }
  };

  const doTopUpBalance = () => {
    if (paymentMethod === 'BANK') {
      props.handleNext();
      setCapsule({ ...capsule, topUpAmount });
    } else {
      api
        .addFunds(paymentMethod, topUpAmount)
        .then((response) => {
          setCapsule({ ...capsule, addFunds: response.data });
          props.handleNext();
        })
        .catch((error) => {
          throw new Error(error);
        });
    }
  };

  const doPayCapsule = () => {
    api
      .payCapsule(capsuleId)
      .then(() => {
        props.handleNext();
        setCapsule({ ...capsule, paymentMethod, stepCover: 9 });
      })
      .catch((error) => {
        throw new Error(error);
      });
  };

  const getData = async () => {
    const responseCapsule = await api.getCapsule(capsuleId);
    const responseUser = await api.getUser();

    setData({
      capsule: responseCapsule.data,
      user: responseUser.data,
    });
  };

  useEffect(() => {
    setCapsule({ ...capsule, stepCover: 7 });
    if (auth.isReady) {
      getData().finally(() => {
        setLoading(false);
      });
    }
  }, [auth]);

  useEffect(() => {
    setCapsule({ ...capsule, paymentMethod, stepCover: 7 });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    firebase.analytics().logEvent('screen_view', { screen_name: '/pay/summary' });
  }, []);

  return (
    <Box>
      {isLoading ? (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <CircularProgress color="inherit" size={40} />
        </Box>
      ) : (
        <Box>
          <Grid container spacing={3} direction="column">
            <Grid item xs={12} sm={12}>
              <Box mb={4}>
                <Typography component="h2" variant="h5" style={{ fontWeight: 600, marginBottom: '24px' }}>
                  {t('translation:pay:title')}
                  <TooltipIcon title={t('translation:pay:titleTooltip')} placement="top" />
                </Typography>

                <List>
                  <ListItem disableGutters>
                    <ListItemText disableTypography className={classes.listItemText}>
                      {t('translation:pay:storagePeriodLabel')} <br />
                      {t('translation:common:until')} {storagePeriodDate}
                    </ListItemText>

                    <ListItemSecondaryAction className={classes.listItemSecondaryAction}>
                      {data.capsule.storePeriod} months
                    </ListItemSecondaryAction>
                  </ListItem>

                  <ListItem disableGutters>
                    <ListItemText disableTypography className={classes.listItemText}>
                      {t('translation:pay:sizeLabel')}
                    </ListItemText>
                    <ListItemSecondaryAction className={classes.listItemSecondaryAction}>
                      {convertBytesToMegaBytes(
                        data.capsule?.files.length === 1
                          ? data.capsule?.files[0].fileSize
                          : data.capsule?.files.reduce((prev, curr) => prev.fileSize + curr.fileSize)
                      )}
                    </ListItemSecondaryAction>
                  </ListItem>

                  <Divider />

                  <ListItem disableGutters>
                    <ListItemText disableTypography className={classes.listItemText}>
                      {t('translation:pay:feeLabel')}
                    </ListItemText>

                    <ListItemSecondaryAction className={classes.listItemSecondaryAction}>
                      $ {data.capsule.capsuleFee.toFixed(2)}
                    </ListItemSecondaryAction>
                  </ListItem>

                  {data.capsule.courierFee > 0 ? (
                    <ListItem disableGutters>
                      <ListItemText disableTypography className={classes.listItemText}>
                        {t('translation:pay:feeDeliveryLabel', {
                          courier: getCourierServiceTitle(data.capsule.courierService),
                        })}
                      </ListItemText>

                      <ListItemSecondaryAction className={classes.listItemSecondaryAction}>
                        $ {data.capsule.courierFee.toFixed(2)}
                      </ListItemSecondaryAction>
                    </ListItem>
                  ) : null}

                  <Divider />

                  <ListItem disableGutters>
                    <ListItemText disableTypography className={classes.listItemTextSummary}>
                      {t('translation:pay:totalLabel')}
                      {totalFee > data.user.balance ? (
                        <div
                          style={{
                            fontSize: 18,
                            color: '#B00020',
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                          }}
                        >
                          <InfoOutlinedIcon style={{ marginRight: 2 }} />
                          {t('translation:pay:notEnoughLabel', {
                            balance: data.user.balance.toFixed(2),
                            fee: totalFee.toFixed(2),
                          })}
                        </div>
                      ) : null}
                    </ListItemText>

                    <ListItemSecondaryAction className={classes.listItemSecondaryActionSummary}>
                      $ {totalFee.toFixed(2)}
                    </ListItemSecondaryAction>
                  </ListItem>

                  <Divider />

                  {totalFee > data.user.balance ? (
                    <ListItem disableGutters>
                      <ListItemText disableTypography className={classes.listItemTextSummary}>
                        {t('translation:pay:topUpBalanceLabel')}
                      </ListItemText>

                      <ListItemSecondaryAction className={classes.listItemSecondaryActionSummary}>
                        {'$ '}
                        {Math.abs(data.user.balance - totalFee) > 0 && Math.abs(data.user.balance - totalFee) < 1
                          ? Number(1).toFixed(2)
                          : Math.abs(data.user.balance - totalFee).toFixed(2)}
                      </ListItemSecondaryAction>
                    </ListItem>
                  ) : (
                    <ListItem disableGutters>
                      <ListItemText disableTypography className={classes.listItemTextSummary}>
                        {t('translation:pay:payFromLabel')}
                      </ListItemText>

                      <ListItemSecondaryAction className={classes.listItemSecondaryActionSummary}>
                        <Box textAlign="right">
                          <Box>
                            {'$ '}
                            {totalFee.toFixed(2)}
                          </Box>

                          <Typography style={{ fontSize: 16, color: '#A6AAB0', lineHeight: 0.5 }}>
                            {t('translation:pay:currentBalanceLabel')} ${data.user.balance.toFixed(2)}
                          </Typography>
                        </Box>
                      </ListItemSecondaryAction>
                    </ListItem>
                  )}
                </List>
              </Box>

              {data.user.balance - totalFee < 0 && (
                <Box>
                  <Typography component="h2" variant="h5" style={{ fontWeight: 600, marginBottom: '24px' }}>
                    {t('translation:pay:title2')}{' '}
                    <TooltipIcon title={t('translation:pay:title2Tooltip')} placement="top" />
                  </Typography>

                  <RadioGroup name="paymentMethod" value={paymentMethod} onChange={handleChangePaymentMethod} row>
                    <FormControlLabel
                      className={classes.formControlLabel}
                      value="BANK"
                      control={<Radio color="primary" />}
                      label={
                        <Box display="flex" alignItems="center">
                          <Box mr={1}>
                            <CreditCardIcon />
                          </Box>
                          <Typography className={classes.formControlLabelText} component="div">
                            <Box>{t('translation:pay:radio1')}</Box>
                          </Typography>
                        </Box>
                      }
                    />

                    <FormControlLabel
                      className={classes.formControlLabel}
                      value="BTC"
                      control={<Radio color="primary" />}
                      label={
                        <Typography className={classes.formControlLabelText} component="div">
                          <Box display="flex" alignItems="center">
                            <Box mr={1}>
                              <FontAwesomeIcon icon={faBitcoin} />
                            </Box>
                            <Box>{t('translation:pay:radio2')}</Box>
                          </Box>
                        </Typography>
                      }
                    />

                    <FormControlLabel
                      className={classes.formControlLabel}
                      value="ETH"
                      control={<Radio color="primary" />}
                      label={
                        <Typography className={classes.formControlLabelText} component="div">
                          <Box display="flex" alignItems="center">
                            <Box mr={1}>
                              <FontAwesomeIcon icon={faEthereum} />
                            </Box>
                            <Box>{t('translation:pay:radio3')}</Box>
                          </Box>
                        </Typography>
                      }
                    />
                  </RadioGroup>
                </Box>
              )}

              <Box mt={8}>
                {data.user.balance - totalFee < 0 ? (
                  <Button variant="contained" color="primary" size="large" onClick={doTopUpBalance}>
                    {t('translation:common:topUp')}
                  </Button>
                ) : (
                  <Button variant="contained" color="primary" size="large" onClick={doPayCapsule}>
                    {t('translation:common:pay')}
                  </Button>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default PaySummary;
