/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Modal } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    background: '#fff',
    margin: 0,
    bottom: 0,
    borderRadius: '20px 20px 0 0',
    boxShadow: '0px 1px 7px rgba(0, 0, 0, 0.15)',
    paddingLeft: 25,
    paddingRight: 25,
    paddingTop: 7,
    position: 'fixed',
    paddingBottom: 80,
  },
  close: {
    backgroundColor: '#E6E6E6',
    margin: '0 auto',
    width: 62,
    height: 4,
    cursor: 'pointer',
  },
  list: {
    width: '100%',
    paddingTop: 10,
  },
  item: {
    padding: '12px 0',
    width: '100%',
  },
  text: {
    color: '#A6AAB0',
    fontSize: 14,
    margin: 0,
  },
  round: {
    borderRadius: 20,
    border: '2px solid #A6AAB0',
    width: 20,
    height: 20,
  },
  inRound: {
    width: 12,
    height: 12,
    background: 'black',
    borderRadius: 20,
  },
}));

const MobileFilter = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { onClose, onSelect, tableMode } = props;

  const init = () => {};

  useEffect(() => {
    init();
  }, []);

  return (
    <Modal open={true} onClose={() => onClose()}>
      <Box
        className={classes.root}
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Box
          onClick={() => onClose()}
          className={classes.close}
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          alignItems="center"
        />

        <Box
          className={classes.list}
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Box
            className={classes.item}
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            onClick={() => onSelect('allitems')}
          >
            <p className={classes.text} style={{ color: tableMode === 'allitems' ? 'black' : '#A6AAB0' }}>
              {t('translation:commonPages:allItems')}
            </p>
            <Box
              className={classes.round}
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              style={{ borderColor: tableMode === 'allitems' ? 'black' : '#A6AAB0' }}
            >
              {tableMode === 'allitems' && <div className={classes.inRound}></div>}
            </Box>
          </Box>
          <Box
            className={classes.item}
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            onClick={() => onSelect('favorites')}
          >
            <p className={classes.text} style={{ color: tableMode === 'favorites' ? 'black' : '#A6AAB0' }}>
              {t('translation:commonPages:favorites')}
            </p>
            <Box
              className={classes.round}
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              style={{ borderColor: tableMode === 'favorites' ? 'black' : '#A6AAB0' }}
            >
              {tableMode === 'favorites' && <div className={classes.inRound}></div>}
            </Box>
          </Box>
          <Box
            className={classes.item}
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            onClick={() => onSelect('deleted')}
          >
            <p className={classes.text} style={{ color: tableMode === 'deleted' ? 'black' : '#A6AAB0' }}>
              {t('translation:commonPages:deleted')}
            </p>
            <Box
              className={classes.round}
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              style={{ borderColor: tableMode === 'deleted' ? 'black' : '#A6AAB0' }}
            >
              {tableMode === 'deleted' && <div className={classes.inRound}></div>}
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default MobileFilter;
