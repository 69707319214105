/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, IconButton, InputAdornment, Modal, TextareaAutosize, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Close from '@material-ui/icons/Close';
import Favorite from '@material-ui/icons/Favorite';
import FavoriteBorderOutlined from '@material-ui/icons/FavoriteBorderOutlined';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { useVaultService } from '../../hooks/useVaultService';

const useStyles = makeStyles(() => ({
  root: {
    width: window.innerWidth > 560 ? '50%' : '100%',
    minHeight: window.innerWidth > 560 ? 0 : '100%',
    maxWidth: 527,
    minWidth: 317,
    background: '#fff',
    margin: '0 auto',
    borderRadius: window.innerWidth > 560 ? 10 : 0,
    boxShadow: '0px 1px 7px rgba(0, 0, 0, 0.15)',
    padding: 25,
  },
  modal: {
    overflow: 'auto',
    paddingTop: window.innerWidth > 560 ? '10vh' : 0,
    paddingBottom: window.innerWidth > 560 ? '10vh' : 0,
  },
  close: {
    color: '#C1C4C9',
    marginTop: 3,
    width: '100%',
    cursor: 'pointer',
  },
  title: {
    color: '#A6AAB0',
    marginTop: -20,
    fontSize: 18,
    marginLeft: 40,
    marginRight: 40,
    textAlign: 'center',
  },
  description1: {
    color: '#2E3542',
    marginTop: 2,
    fontSize: 14,
    marginLeft: 40,
    marginRight: 40,
    textAlign: 'center',
  },
  fieldsWrap: {
    marginTop: 30,
    paddingLeft: window.innerWidth > 560 ? 40 : 0,
    paddingRight: window.innerWidth > 560 ? 40 : 0,
    width: '100%',
    flexDirection: 'column',
  },
  input: {
    width: '100%',
  },
  textarea: {
    width: '100%',
    borderRadius: 10,
    background: '#F4F8FC',
    color: '#2E3542',
    border: '1px solid #F4F8FC',
    padding: '17px 22px',
    fontSize: 16,
    fontFamily: 'Roboto',
    '&:focus': {
      outline: 'none',
    },
    '&:placeholder': {
      outline: 'none',
      color: '#A6AAB0',
    },
  },
  favBtn: {},
  favText: {
    color: '#A6AAB0',
    fontSize: 14,
    marginLeft: 3,
  },
}));

const INITIAL_STATE = {
  file: '',
  pwd: '',
  loc: '',
  desc: '',
  fav: false,
};

const CreateFileAccessPassword = (props) => {
  const classes = useStyles();
  const vaultService = useVaultService();
  const { t } = useTranslation();
  const [isLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [state, setState] = useState(INITIAL_STATE);
  const { currentVault, updateCurrentVault } = vaultService;
  const { onClose, editRowId } = props;

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const onChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const toogleFavorite = () => {
    setState({ ...state, fav: !state.fav });
  };

  const init = () => {
    if (editRowId) {
      const currentRecWeb = currentVault.rec_file.find((item) => item.id === editRowId);
      if (currentRecWeb) {
        setState({ ...currentRecWeb });
      }
    }
  };

  const onSubmit = () => {
    const data = {
      ...state,
      id: uuidv4(),
      cre: new Date().getTime(),
      upd: new Date().getTime(),
    };
    if (editRowId) {
      const currentRecWeb = currentVault.rec_file.find((item) => item.id === editRowId);
      currentVault.rec_file = currentVault.rec_file.filter((item) => item.id !== editRowId);
      currentVault.rec_file.push({
        ...data,
        cre: currentRecWeb ? currentRecWeb.cre : new Date().getTime(),
        id: editRowId,
      });
    } else {
      currentVault.rec_file.push(data);
    }
    updateCurrentVault();
    onClose();
  };

  useEffect(() => {
    init();
  }, []);

  const isInvalid = !(state.file && state.loc && state.pwd);

  return (
    <Modal open={true} className={classes.modal} onClose={() => onClose()}>
      <Box
        className={classes.root}
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Box
          onClick={() => onClose()}
          className={classes.close}
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Close />
        </Box>
        <Box
          className={classes.title}
          display="flex"
          flexDirection="columm"
          justifyContent="center"
          alignItems="center"
        >
          {editRowId ? 'EDIT' : 'CREATE NEW'}
        </Box>
        <Box
          className={classes.description1}
          display="flex"
          flexDirection="columm"
          justifyContent="center"
          alignItems="center"
        >
          {t('translation:commonPages:filePwd')}
        </Box>
          
        <Box className={classes.fieldsWrap} display="flex" justifyContent="flex-start" alignItems="center">
          <TextField
            type="text"
            id="file"
            name="file"
            value={state.file}
            inputProps={{
              maxLength: 200,
            }}
            color="primary"
            onChange={onChange}
            placeholder={t('translation:commonPages:fileName')}
            label={t('translation:commonPages:fileName')}
            required
            fullWidth
            InputProps={{
              classes: {
                input: classes.input,
              },
            }}
            style={{ marginBottom: 24 }}
          />
          <TextField
            type="text"
            style={{ '-webkit-text-security': showPassword ? 'none' : 'disc', marginBottom: 24 }}
            id="pwd"
            name="pwd"
            autoComplete="false"
            inputProps={{
              autoComplete: 'false',
              maxLength: 64,
            }}
            value={state.pwd}
            onChange={onChange}
            placeholder={t('translation:auth:signIn:inputPasswordPlaceholder')}
            label={t('translation:commonPages:password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
              classes: {
                input: classes.input,
              },
            }}
            required
            fullWidth
          />
          <TextField
            type="text"
            id="loc"
            name="loc"
            inputProps={{
              maxLength: 64,
              autoComplete: 'false',
            }}
            autoComplete="false"
            value={state.loc}
            color="primary"
            onChange={onChange}
            placeholder={t('translation:commonPages:location')}
            label={t('translation:commonPages:location')}
            required
            fullWidth
            InputProps={{
              classes: {
                input: classes.input,
              },
            }}
            style={{ marginBottom: 24 }}
          />
          <TextareaAutosize
            aria-label="minimum height"
            rowsMin={2}
            maxLength={264}
            placeholder={t('translation:commonPages:desc')}
            className={classes.textarea}
            id="desc"
            name="desc"
            value={state.desc}
            onChange={onChange}
          />
          <Box
            display="flex"
            mt={5}
            flexDirection="row"
            alignItems="center"
            justifyContent="flex-start"
            style={{ width: '100%', marginLeft: -20 }}
          >
            <IconButton
              className={classes.favBtn}
              aria-label="view list"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              style={{ color: state.fav ? '#E41139' : '#A6AAB0' }}
              onClick={toogleFavorite}
            >
              {state.fav ? <Favorite /> : <FavoriteBorderOutlined />}
            </IconButton>
            <span className={classes.favText}>{t('translation:commonPages:addFav')}</span>
          </Box>
        </Box>
        <Box
          className={classes.passwordField}
          display="flex"
          flexDirection="columm"
          justifyContent="center"
          alignItems="center"
          mt={7}
          mb={5}
        >
          <Button style={{ marginRight: 20 }} onClick={() => onClose()} variant="outlined" color="primary">
            {t('translation:commonPages:cancel')}
          </Button>
          <Button variant="contained" disabled={isInvalid || isLoading} onClick={() => onSubmit()} color="primary">
            {t('translation:commonPages:save')}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default CreateFileAccessPassword;
