import { IconButton, makeStyles } from '@material-ui/core';
import React from 'react';
import { ReactComponent as FileCopy } from '../assets/file-copy.svg';

const useStyles = makeStyles((theme) => ({
  icon: { padding: 9, color: '#A6AAB0', width: 42 },
}));

const CopyToClipboard = (props) => {
  const classes = useStyles();

  const copyTextToClipboard = (text) => {
    let el = null;
    const textArea = document.createElement('textarea');
    textArea.style.position = 'fixed';
    textArea.style.top = 0;
    textArea.style.left = 0;
    textArea.style.zIndex = 1000;
    textArea.style.width = '2em';
    textArea.style.height = '2em';
    textArea.style.padding = 0;
    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';
    textArea.style.background = 'transparent';
    textArea.value = text;
    if (props.fromView) {
      el = document.getElementById('view');
      if (el) {
        el.appendChild(textArea);
      }
    } else {
      document.body.appendChild(textArea);
    }
    textArea.focus();
    textArea.select();
    try {
      document.execCommand('copy');
    } catch (err) {
      console.log('Oops, unable to copy');
    }

    if (props.fromView) {
      if (el) {
        el.removeChild(textArea);
      }
    } else {
      document.body.removeChild(textArea);
    }
  };

  return (
    <IconButton
      className={classes.icon}
      aria-label="view list"
      aria-controls="menu-appbar"
      aria-haspopup="true"
      color="inherit"
      onClick={() => copyTextToClipboard(props.text)}
    >
      <FileCopy />
    </IconButton>
  );
};

export default CopyToClipboard;
