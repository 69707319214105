/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, IconButton, Modal } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Close from '@material-ui/icons/Close';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CopyToClipboard from '../../components/CopyToClipboard';
import { useVaultService } from '../../hooks/useVaultService';

const useStyles = makeStyles(() => ({
  root: {
    width: window.innerWidth > 560 ? '50%' : '100%',
    minHeight: window.innerWidth > 560 ? 0 : '100%',
    maxWidth: 527,
    minWidth: 317,
    background: '#fff',
    margin: '0 auto',
    borderRadius: window.innerWidth > 560 ? 10 : 0,
    boxShadow: '0px 1px 7px rgba(0, 0, 0, 0.15)',
    padding: 25,
  },
  modal: {
    overflow: 'auto',
    paddingTop: window.innerWidth > 560 ? '10vh' : 0,
    paddingBottom: window.innerWidth > 560 ? '10vh' : 0,
  },
  close: {
    color: '#C1C4C9',
    marginTop: 3,
    width: '100%',
    cursor: 'pointer',
  },
  title: {
    color: '#A6AAB0',
    marginTop: -20,
    fontSize: 18,
    marginLeft: 40,
    marginRight: 40,
    textAlign: 'center',
  },
  fieldsWrap: {
    marginTop: 30,
    paddingLeft: window.innerWidth > 560 ? 40 : 0,
    paddingRight: window.innerWidth > 560 ? 40 : 0,
    width: '100%',
    flexDirection: 'column',
  },
  row: {
    width: '100%',
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
    paddingBottom: 10,
    paddingTop: 10,
  },
  lastRow: {
    width: '100%',
    paddingBottom: 10,
    paddingTop: 10,
  },
  rowTitle: {
    color: '#A6AAB0',
    fontSize: 16,
  },
  rowValue: {
    color: '#2E3542',
    fontSize: 16,
    minHeight: 43,
    hyphens: 'auto',
    maxWidth: '70%',
    wordBreak: 'break-all',
  },
  icon: { padding: 9, color: '#A6AAB0', width: 42 },
  linkText: { color: '#E41139', fontSize: 16, fontWeight: 'normal', textDecoration: 'underline', flex: 'auto' },
}));

const ViewCryptoWallets = (props) => {
  const classes = useStyles();
  const { currentVault } = useVaultService();
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [showPrivateKey, setShowPrivateKey] = useState(false);
  const [showPublicKey, setShowPublicKey] = useState(false);
  const { rowId, onClose, onEdit } = props;

  const currentRecCrypt = currentVault.rec_crypt.find((item) => item.id === rowId);

  const init = () => {};

  useEffect(() => {
    init();
  }, []);

  return (
    <Modal className={classes.modal} open={true} onClose={() => onClose()}>
      <Box
        className={classes.root}
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Box
          onClick={() => onClose()}
          className={classes.close}
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Close />
        </Box>
        <Box
          className={classes.title}
          display="flex"
          flexDirection="columm"
          justifyContent="center"
          alignItems="center"
          id="view"
        >
          {t('translation:commonPages:view')}
        </Box>
        <Box
          className={classes.description1}
          display="flex"
          flexDirection="columm"
          justifyContent="center"
          alignItems="center"
        >
          {t('translation:commonPages:cryptoWallet')}
        </Box>

        <Box className={classes.fieldsWrap} display="flex" justifyContent="flex-start" alignItems="center">
          <Box
            className={classes.row}
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box className={classes.rowTitle}>{t('translation:commonPages:name')}</Box>
            <Box
              className={classes.rowValue}
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="flex-end"
            >
              {currentRecCrypt.name}
              <CopyToClipboard fromView text={currentRecCrypt.name} />
            </Box>
          </Box>

          <Box
            className={classes.row}
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box className={classes.rowTitle}>{t('translation:commonPages:walletName')}</Box>
            <Box
              className={classes.rowValue}
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="flex-end"
            >
              {currentRecCrypt.wfile}
              <CopyToClipboard fromView text={currentRecCrypt.wfile} />
            </Box>
          </Box>

          <Box
            className={classes.row}
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box className={classes.rowTitle}>{t('translation:commonPages:address')}</Box>
            <Box
              className={classes.rowValue}
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="flex-end"
            >
              {currentRecCrypt.waddr}
              <CopyToClipboard fromView text={currentRecCrypt.waddr} />
            </Box>
          </Box>

          <Box
            className={classes.row}
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box className={classes.rowTitle}>{t('translation:commonPages:password')}</Box>
            <Box
              className={classes.rowValue}
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="flex-end"
            >
              {showPassword ? currentRecCrypt.wpwd : '******'}
              <IconButton
                className={classes.icon}
                aria-label="view list"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
              <CopyToClipboard fromView text={currentRecCrypt.wpwd} />
            </Box>
          </Box>

          <Box
            className={classes.row}
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box className={classes.rowTitle}>{t('translation:commonPages:seedPhrase')}</Box>
            <Box
              className={classes.rowValue}
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="flex-end"
            >
              {currentRecCrypt.wseed}
              <CopyToClipboard fromView text={currentRecCrypt.wseed} />
            </Box>
          </Box>

          <Box
            className={classes.row}
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box className={classes.rowTitle}>{t('translation:commonPages:customWords')}</Box>
            <Box
              className={classes.rowValue}
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="flex-end"
            >
              {currentRecCrypt.wcseed}
              <CopyToClipboard fromView text={currentRecCrypt.wcseed} />
            </Box>
          </Box>

          <Box
            className={classes.row}
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box className={classes.rowTitle}>{t('translation:commonPages:pubKey')}</Box>
            <Box
              className={classes.rowValue}
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="flex-end"
            >
              {showPublicKey ? currentRecCrypt.wkeypub : '******'}
              <IconButton
                className={classes.icon}
                aria-label="view list"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
                onClick={() => setShowPublicKey(!showPublicKey)}
              >
                {showPublicKey ? <Visibility /> : <VisibilityOff />}
              </IconButton>
              <CopyToClipboard fromView text={currentRecCrypt.wkeypub} />
            </Box>
          </Box>

          <Box
            className={classes.row}
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box className={classes.rowTitle}>{t('translation:commonPages:privKey')}</Box>
            <Box
              className={classes.rowValue}
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="flex-end"
            >
              {showPrivateKey ? currentRecCrypt.wkeypriv : '******'}
              <IconButton
                className={classes.icon}
                aria-label="view list"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
                onClick={() => setShowPrivateKey(!showPrivateKey)}
              >
                {showPrivateKey ? <Visibility /> : <VisibilityOff />}
              </IconButton>
              <CopyToClipboard fromView text={currentRecCrypt.wkeypriv} />
            </Box>
          </Box>

          {currentRecCrypt.desc && (
            <Box
              className={classes.lastRow}
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              justifyContent="flex-start"
            >
              <Box className={classes.rowTitle}>
                {t('translation:commonPages:desc')}
                <CopyToClipboard fromView text={currentRecCrypt.desc} />
              </Box>
              <Box
                className={classes.rowValue}
                style={{ maxWidth: '100%' }}
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="flex-end"
              >
                {currentRecCrypt.desc}
              </Box>
            </Box>
          )}
        </Box>
        <Box
          className={classes.passwordField}
          display="flex"
          flexDirection="columm"
          justifyContent="center"
          alignItems="center"
          mt={7}
          mb={5}
        >
          <Button style={{ marginRight: 20 }} onClick={() => onClose()} variant="outlined" color="primary">
            {t('translation:commonPages:cancel')}
          </Button>
          <Button variant="contained" onClick={() => onEdit()} color="primary">
            {t('translation:commonPages:edit')}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ViewCryptoWallets;
