/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Modal } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Close from '@material-ui/icons/Close';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useVaultService } from '../../hooks/useVaultService';

const useStyles = makeStyles(() => ({
  root: {
    width: window.innerWidth > 560 ? '50%' : '100%',
    minHeight: window.innerWidth > 560 ? 0 : '100%',
    maxWidth: 527,
    minWidth: 317,
    background: '#fff',
    margin: '0 auto',
    borderRadius: window.innerWidth > 560 ? 10 : 0,
    boxShadow: '0px 1px 7px rgba(0, 0, 0, 0.15)',
    padding: 25,
  },
  modal: {
    overflow: 'auto',
    paddingTop: window.innerWidth > 560 ? '10vh' : 0,
    paddingBottom: window.innerWidth > 560 ? '10vh' : 0,
  },
  close: {
    color: '#C1C4C9',
    marginTop: 3,
    width: '100%',
    cursor: 'pointer',
  },
  title: {
    color: '#A6AAB0',
    marginTop: 24,
    fontSize: 18,
    marginLeft: 40,
    marginRight: 40,
    textAlign: 'center',
  },
  fieldsWrap: {
    marginTop: 30,
    paddingLeft: window.innerWidth > 560 ? 40 : 0,
    paddingRight: window.innerWidth > 560 ? 40 : 0,
    width: '100%',
    flexDirection: 'column',
    overflow: 'auto',
  },
  question: {
    fontSize: 18,
    marginBottom: 5,
    color: '#2E3542',
    textAlign: 'center',
  },
  desc: {
    color: '#A6AAB0',
    fontSize: 14,
    textAlign: 'center',
  },
  icon: { padding: 9, color: '#A6AAB0', width: 42 },
  linkText: { color: '#E41139', fontSize: 16, fontWeight: 'normal', textDecoration: 'underline', flex: 'auto' },
}));

const DeleteItem = (props) => {
  const classes = useStyles();
  const { currentVault, deleteItem, deleteSelectedItems, updateCurrentVault } = useVaultService();
  const [isLoading, setLoading] = useState(false);
  const { rowId, onClose, deleteSelected, checkedArr } = props;
  const { t } = useTranslation();

  const init = () => {};

  const handleDelete = () => {
    setLoading(true);
    if (deleteSelected) {
      const sendDataArr = checkedArr
        .filter((item) => item.checked)
        .map((item) => {
          const found = currentVault.rec_other.find((i) => i.id === item.id);
          if (found) {
            return {
              id: found.id,
              data: found,
            };
          } else {
            return null;
          }
        })
        .filter((item) => !!item);
      deleteSelectedItems(sendDataArr)
        .then(() => {
          const deletedItems = sendDataArr.map((item) => {
            return {
              id: item.data.id,
              del: new Date().getTime(),
              name: item.data.name,
            };
          });
          const savedItems = currentVault.rec_other.filter((item) => !sendDataArr.find((i) => i.id === item.id));
          currentVault.rec_other = [...savedItems, ...deletedItems];
          updateCurrentVault();
          onClose();
        })
        .catch((err) => {
          alert(err);
        })
        .finally(() => setLoading(false));
    } else if (rowId) {
      const currentRecOther = currentVault.rec_other.find((item) => item.id === rowId);
      const sendData = { ...currentRecOther };

      deleteItem(currentRecOther.id, sendData)
        .then(() => {
          currentVault.rec_other = currentVault.rec_other.filter((item) => item.id !== rowId);
          const newRecWeb = {
            id: currentRecOther.id,
            del: new Date().getTime(),
            name: currentRecOther.name,
          };
          currentVault.rec_other.push(newRecWeb);
          updateCurrentVault();
          onClose();
        })
        .catch((err) => {
          alert(err);
        })
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <Modal className={classes.modal} open={true} onClose={() => onClose()}>
      <Box
        className={classes.root}
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Box
          onClick={() => onClose()}
          className={classes.close}
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Close />
        </Box>
        <Box
          className={classes.title}
          display="flex"
          flexDirection="columm"
          justifyContent="center"
          alignItems="center"
        >
          {t('translation:commonPages:deleteEntry')}
        </Box>
          
        <Box className={classes.fieldsWrap} display="flex" justifyContent="flex-start" alignItems="center">
          <Box className={classes.question}>{t('translation:commonPages:deleteEntryQuestion')}</Box>
          <Box className={classes.desc}>{t('translation:commonPages:deleteEntryDesc')}</Box>
        </Box>
        <Box
          className={classes.passwordField}
          display="flex"
          flexDirection="columm"
          justifyContent="center"
          alignItems="center"
          mt={7}
          mb={5}
        >
          <Button style={{ marginRight: 20 }} onClick={() => onClose()} variant="outlined" color="primary">
            {t('translation:commonPages:cancel')}
          </Button>
          <Button variant="contained" disabled={isLoading} onClick={() => handleDelete()} color="primary">
            {t('translation:commonPages:delete')}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default DeleteItem;
