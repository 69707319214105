import { Box, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Apps from '@material-ui/icons/Apps';
import ViewHeadline from '@material-ui/icons/ViewHeadline';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

const useStyles = makeStyles(() => ({
  btn: {
    padding: 5,
    opacity: 0.4,
    '&:hover': {
      opacity: 1,
    },
    '&.active': {
      opacity: 1,
    },
  },
}));

const ViewSwitcher = (props) => {
  const classes = useStyles();
  const { onChangeViewMode } = props;
  const [view, setView] = useState(window.innerWidth > 560 ? 'table' : 'card');

  const init = () => {
    onChangeViewMode(view);
  };

  useEffect(() => {
    init();
  });

  return (
    <Box display="flex" alignItems="center" flexDirection="row" justifyContent="flex-end" className={classes.btnWrap}>
      <IconButton
        className={classNames(classes.btn, view === 'card' ? 'active' : '')}
        aria-label="view card"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        color="inherit"
        onClick={() => {
          setView('card');
          onChangeViewMode('card');
        }}
      >
        <Apps />
      </IconButton>
      <IconButton
        className={classNames(classes.btn, view === 'table' ? 'active' : '')}
        aria-label="view list"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        color="inherit"
        onClick={() => {
          setView('table');
          onChangeViewMode('table');
        }}
      >
        <ViewHeadline />
      </IconButton>
    </Box>
  );
};

export default ViewSwitcher;
