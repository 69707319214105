/* eslint-disable react-hooks/exhaustive-deps */
import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorOutlinedIcon from '@material-ui/icons/ErrorOutlined';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import { useApi } from '../../hooks/useApi';
import { useAuth } from '../../hooks/useAuth';
import { useRouter } from '../../hooks/useRouter';
import { useVaultService } from '../../hooks/useVaultService';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2, 2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  header: {
    margin: theme.spacing(0, 0, 10, 0),
  },
  icon: {
    margin: theme.spacing(0, 0, 2, 0),
    fontSize: '2.7rem',
    color: theme.palette.success.main,
  },
  iconError: {
    margin: theme.spacing(0, 0, 2, 0),
    fontSize: '2.7rem',
    color: '#E41139',
  },
  title: {
    margin: theme.spacing(0, 0, 2, 0),
  },
  subtitle: {
    maxWidth: 600,
  },
  bottom: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: theme.spacing(5, 0, 0, 0),
  },
  error: {
    color: '#E41139',
  },
}));

const VerifyEmail = () => {
  const classes = useStyles();
  const router = useRouter();
  const auth = useAuth();
  const { t } = useTranslation();
  const [error, setError] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const { resetVaultService } = useVaultService();
  const { resetUser } = useApi();

  const code = router.query?.oobCode;

  useEffect(() => {
    if (!auth.user?.emailVerified) {
      auth
        .applyActionCode(code)
        .then(() => {
          auth.signOut().then(() => {
            resetVaultService();
            resetUser();
            router.history.push(ROUTES.AUTH_SIGN_IN);
          });
        })
        .catch((error) => {
          setError(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }

    document.title = 'Account verified - allPWD';

    return () => {};
  }, []);

  return isLoading ? (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
      <CircularProgress color="inherit" size={40} />
    </Box>
  ) : (
    <div className={classes.root}>
      <Box className={classes.header} textAlign="center">
        {error ? (
          <Box>
            <Box className={classes.title}>
              <ErrorOutlinedIcon className={classes.iconError} />

              <Typography component="h1" variant="h4">
                {t('translation:auth:verifyEmail:titleError')}
              </Typography>
            </Box>

            <Box className={classes.subtitle}>
              <Typography component="h1" variant="h5" className={classes.error}>
                {error.message}
              </Typography>
            </Box>
          </Box>
        ) : (
          <Box>
            <Box className={classes.title}>
              <CheckCircleIcon className={classes.icon} />

              <Typography component="h1" variant="h4">
                {t('translation:auth:verifyEmail:titleSuccess')}
              </Typography>
            </Box>

            <Box className={classes.subtitle}>
              <Typography component="h2" variant="h5">
                {t('translation:auth:verifyEmail:subtitleSuccess')}
              </Typography>
            </Box>
          </Box>
        )}

        <Box display="flex" justifyContent="center" mt={8} alignItems="center">
          <Box mr={1}>
            <Button variant="outlined" component={Link} to={ROUTES.USER_DASHBOARD}>
              {t('translation:auth:verifyEmail:dashboardButton')}
            </Button>
          </Box>

          <Box>
            <Button variant="outlined" component={Link} to="/authentication?mode=verifyPlease">
              {t('translation:auth:verifyEmail:resendButton')}
            </Button>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default VerifyEmail;
